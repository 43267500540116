import { css, CSSObject, SerializedStyles } from "@emotion/react";

const spacing = {
  zero: "0px",
  xxxs: "2px",
  xxs: "4px",
  xs: "8px",
  sm: "12px",
  md: "16px",
  lg: "20px",
  xl: "32px",
  xxl: "64px",
  xxxl: "128px",
} as const;

type IBoxPropValue = typeof spacing[keyof typeof spacing] | null | "auto";

export interface IBoxStyles {
  /** new box styles */
  margin?: IBoxPropValue;
  marginX?: IBoxPropValue;
  marginY?: IBoxPropValue;
  marginTop?: IBoxPropValue;
  marginRight?: IBoxPropValue;
  marginBottom?: IBoxPropValue;
  marginLeft?: IBoxPropValue;

  padding?: IBoxPropValue;
  paddingX?: IBoxPropValue;
  paddingY?: IBoxPropValue;
  paddingTop?: IBoxPropValue;
  paddingRight?: IBoxPropValue;
  paddingBottom?: IBoxPropValue;
  paddingLeft?: IBoxPropValue;

  /** Everything below is deprecated! */
  /** margin: auto */
  marginAuto?: boolean;
  /** margin-left/right: auto */
  marginAutoX?: boolean;
  /** margin-top/bottom: auto */
  marginAutoY?: boolean;
  /** margin-top: auto */
  marginAutoTop?: boolean;
  /** margin-left: auto */
  marginAutoLeft?: boolean;
  /** margin-right: auto */
  marginAutoRight?: boolean;
  /** margin-bottom: auto */
  marginAutoBottom?: boolean;

  // /** margin: 24px */
  oldMargin?: boolean;
  /** margin-left/right: 24px */
  oldMarginX?: boolean;
  /** margin-top/bottom: 24px */
  oldMarginY?: boolean;
  /** margin-top: 24px */
  oldMarginTop?: boolean;
  /** margin-left: 24px */
  oldMarginLeft?: boolean;
  /** margin-right: 24px */
  oldMarginRight?: boolean;
  /** margin-bottom: 24px */
  oldMarginBottom?: boolean;

  /** margin: 12px */
  marginHalf?: boolean;
  /** margin-left/right: 12px */
  marginHalfX?: boolean;
  /** margin-top/bottom: 12px */
  marginHalfY?: boolean;
  /** margin-top: 12px */
  marginHalfTop?: boolean;
  /** margin-left: 12px */
  marginHalfLeft?: boolean;
  /** margin-right: 12px */
  marginHalfRight?: boolean;
  /** margin-bottom: 12px */
  marginHalfBottom?: boolean;

  /** pad: 24px */
  pad?: boolean;
  /** pad-left/right: 24px */
  padX?: boolean;
  /** pad-top/bottom: 24px */
  padY?: boolean;
  /** pad-top: 24px */
  padTop?: boolean;
  /** pad-left: 24px */
  padLeft?: boolean;
  /** pad-right: 24px */
  padRight?: boolean;
  /** pad-bottom: 24px */
  padBottom?: boolean;

  /** pad: 12px */
  padHalf?: boolean;
  /** pad-left/right: 12px */
  padHalfX?: boolean;
  /** pad-top/bottom: 12px */
  padHalfY?: boolean;
  /** pad-top: 12px */
  padHalfTop?: boolean;
  /** pad-left: 12px */
  padHalfLeft?: boolean;
  /** pad-right: 12px */
  padHalfRight?: boolean;
  /** pad-bottom: 12px */
  padHalfBottom?: boolean;
}

export const useBoxProps = (props: IBoxStyles): IBoxStyles => {
  return {
    margin: props.margin,

    marginX: props.marginX,
    marginY: props.marginY,

    marginTop: props.marginTop,
    marginRight: props.marginRight,
    marginBottom: props.marginBottom,
    marginLeft: props.marginLeft,

    padding: props.padding,

    paddingX: props.paddingX,
    paddingY: props.paddingY,

    paddingTop: props.paddingTop,
    paddingRight: props.paddingRight,
    paddingBottom: props.paddingBottom,
    paddingLeft: props.paddingLeft,
  };
};

export const withBoxStyles = (props: IBoxStyles): SerializedStyles => {
  const finalPaddingY = props.padY === true ? "24px" : props.padHalfY === true ? "12px" : "0";
  const finalPaddingX = props.padX === true ? "24px" : props.padHalfX === true ? "12px" : "0";

  const finalMarginY =
    props.oldMarginY === true
      ? "24px"
      : props.marginHalfY === true
      ? "12px"
      : props.marginAutoY === true
      ? "auto"
      : "0";

  const finalMarginX =
    props.oldMarginX === true
      ? "24px"
      : props.marginHalfX === true
      ? "12px"
      : props.marginAutoX === true
      ? "auto"
      : "0";

  return css`
    /* BEGIN new spacing prop */
    margin: ${props.margin};

    margin-left: ${props.marginX};
    margin-right: ${props.marginX};
    margin-top: ${props.marginY};
    margin-bottom: ${props.marginY};

    margin-top: ${props.marginTop};
    margin-right: ${props.marginRight};
    margin-bottom: ${props.marginBottom};
    margin-left: ${props.marginLeft};

    padding: ${props.padding};

    padding-left: ${props.paddingX};
    padding-right: ${props.paddingX};
    padding-top: ${props.paddingY};
    padding-bottom: ${props.paddingY};

    padding-top: ${props.paddingTop};
    padding-right: ${props.paddingRight};
    padding-bottom: ${props.paddingBottom};
    padding-left: ${props.paddingLeft};
    /* END new spacing prop */

    /** Everything below is deprecated! */
    /* default margin */
    margin: ${props.oldMargin === true && "24px"};
    margin: ${props.marginHalf === true && "12px"};
    margin: ${props.marginAuto === true && "auto"};
    margin: ${(finalMarginY !== "0" || finalMarginX !== "0") && `${finalMarginY} ${finalMarginX}`};

    /* full margin */
    margin-left: ${props.oldMarginLeft === true && "24px"};
    margin-right: ${props.oldMarginRight === true && "24px"};
    margin-top: ${props.oldMarginTop === true && "24px"};
    margin-bottom: ${props.oldMarginBottom === true && "24px"};

    /* half margin */
    margin-left: ${props.marginHalfLeft === true && "12px"};
    margin-right: ${props.marginHalfRight === true && "12px"};
    margin-top: ${props.marginHalfTop === true && "12px"};
    margin-bottom: ${props.marginHalfBottom === true && "12px"};

    /* auto margin */
    margin-left: ${props.marginAutoLeft === true && "auto"};
    margin-right: ${props.marginAutoRight === true && "auto"};
    margin-top: ${props.marginAutoTop === true && "auto"};
    margin-bottom: ${props.marginAutoBottom === true && "auto"};

    /* default padding */
    padding: ${props.pad === true && "24px"};
    padding: ${props.padHalf === true && "12px"};
    padding: ${(finalPaddingY !== "0" || finalPaddingX !== "0") &&
    `${finalPaddingY} ${finalPaddingX}`};

    /* full padding */
    padding-left: ${props.padLeft === true && "24px"};
    padding-right: ${props.padRight === true && "24px"};
    padding-top: ${props.padTop === true && "24px"};
    padding-bottom: ${props.padBottom === true && "24px"};

    /* half padding */
    padding-left: ${props.padHalfLeft === true && "12px"};
    padding-right: ${props.padHalfRight === true && "12px"};
    padding-top: ${props.padHalfTop === true && "12px"};
    padding-bottom: ${props.padHalfBottom === true && "12px"};
  `;
};

export interface IBorderStyles {
  border?: boolean;
  borderX?: boolean;
  borderY?: boolean;
  borderTop?: boolean;
  borderLeft?: boolean;
  borderRight?: boolean;
  borderBottom?: boolean;
  borderRadius?: boolean | "4px" | "8px";
}

// TODO: get rid of color prop
export const withBorderStyles = (props: IBorderStyles, color?: string): SerializedStyles => {
  const borderStyle = `1px solid ${color}`;
  return css`
    border: ${props.border === true && borderStyle};
    border-left: ${props.borderLeft === true && borderStyle};
    border-right: ${props.borderRight === true && borderStyle};
    border-top: ${props.borderTop === true && borderStyle};
    border-bottom: ${props.borderBottom === true && borderStyle};
    border-radius: ${typeof props.borderRadius === "string"
      ? props.borderRadius
      : props.borderRadius === true && "4px"};
  `;
};

export interface IGridStyles {
  /** sets the grid-row css prop. Shorthand for 'grid-row-start' and 'grid-row-end'. */
  gridRow?: CSSObject["gridRow"];
  /** sets the grid-column css prop. Shorthand for 'grid-column-start' and 'grid-column-end'. */
  gridColumn?: CSSObject["gridColumn"];
  gridFullWidth?: boolean;
}

export const withGridStyles = (props: IGridStyles): SerializedStyles => {
  return css`
    grid-row: ${props.gridRow};
    grid-column: ${props.gridColumn};
    grid-column: ${props.gridFullWidth === true && "1/-1"};
  `;
};

export interface IFlexStyles {
  alignSelf?: CSSObject["alignSelf"];
  justifySelf?: CSSObject["justifySelf"];

  alignItems?: CSSObject["alignItems"];
  justifyContent?: CSSObject["justifyContent"];
  flexWrap?: CSSObject["flexWrap"];

  grow?: number;
  shrink?: number;
}

export const withFlexStyles = (props: IFlexStyles): SerializedStyles => {
  return css`
    align-self: ${props.alignSelf};
    justify-self: ${props.justifySelf};
    justify-content: ${props.justifyContent};
    align-items: ${props.alignItems};
    flex-wrap: ${props.flexWrap};
    flex-grow: ${props.grow};
    flex-shrink: ${props.shrink};
  `;
};
