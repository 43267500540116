import { t } from "~src/designSystem/theme";

export const colorPalette = {
  Neutrals: {
    Light: {
      "50": "#FAFAFA",
      "100": "#F4F5F5",
      "200": "#EFF0F0",
      "300": "#DFE1E2",
      "400": "#CACCCE",
      "500": "#A0A4A7",
      "600": "#808689",
      "700": "#62676A",
      "800": "#45484A",
      "900": "#313335",
      "White": "#ffffff",
      "Black": "#000000",
    },
    Dark: {
      "50": t.colors.surfaceBackground.toString() as string,
      "100": "#17181C",
      "200": "#1C1D21",
      "300": "#24252E",
      "400": "#363845",
      "500": "#5E606E",
      "600": "#85868E",
      "700": "#B3B4BC",
      "800": "#DFE0E2",
      "900": "#F4F5F5",
      "Black": "#000000",
      "White": "#ffffff",
    },
  },
  Semantic: {
    Light: {
      Negative: {
        "100": "#f9dcdc",
        "200": "#f0a8a8",
        "300": "#e67575",
        "400": "#dd4141",
        "500": "#ad1f1f",
      },
      Warning: {
        "100": "#fff1cc",
        "200": "#ffe499",
        "300": "#ffd35c",
        "400": "#ffbe0a",
        "500": "#e09600",
      },
      Positive: {
        "100": "#e4f1e9",
        "200": "#a7ddbc",
        "300": "#60c78a",
        "400": "#25a759",
        "500": "#178c46",
      },
      Action: {
        "100": "#eff3fa",
        "200": "#c1cfeb",
        "300": "#839fd8",
        "400": "#547bc9",
        "500": "#2f5093",
      },
    },
    Dark: {
      Negative: {
        "100": "#340909",
        "200": "#681313",
        "300": "#9c1c1c",
        "400": "#dd4141",
        "500": "#f56666",
      },
      Warning: {
        "100": "#291e00",
        "200": "#664b00",
        "300": "#b88700",
        "400": "#f0b105",
        "500": "#ffc400",
      },
      Positive: {
        "100": "#17261d",
        "200": "#1d492f",
        "300": "#1f6f3f",
        "400": "#25a759",
        "500": "#36d375",
      },
      Action: {
        "100": "#141b2a",
        "200": "#2c374e",
        "300": "#3d588f",
        "400": "#547bc9",
        "500": "#7c9dde",
      },
    },
  },
  Other: {
    Purple: {
      Purple70: "#12102d",
      Purple60: "#1e1b4b",
      Purple50: "#302a79",
      Purple40: "#6059c5",
      Purple30: "#8b86d5",
      Purple20: "#b7b4e4",
      Purple10: "#e2e1f4",
    },
    Blue: {
      Blue70: "#141b2a",
      Blue60: "#2c374e",
      Blue50: "#3d588f",
      Blue40: "#547bc9",
      Blue30: "#839fd8",
      Blue20: "#c1cfeb",
      Blue10: "#eff3fa",
    },
    Teal: {
      Teal70: "#11272c",
      Teal60: "#1d4049",
      Teal50: "#398193",
      Teal40: "#50a6bb",
      Teal30: "#98cad7",
      Teal20: "#c4e1e8",
      Teal10: "#e2f0f4",
    },
    Seaweed: {
      Seaweed70: "#0b322e",
      Seaweed60: "#16645d",
      Seaweed50: "#1d867b",
      Seaweed40: "#25a79a",
      Seaweed30: "#97d8d2",
      Seaweed20: "#c3e9e5",
      Seaweed10: "#e1f4f2",
    },
    Green: {
      Green70: "#17261d",
      Green60: "#1d492f",
      Green50: "#1f6f3f",
      Green40: "#25a759",
      Green30: "#60c78a",
      Green20: "#a7ddbc",
      Green10: "#e4f1e9",
    },
    Mint: {
      Mint70: "#283a04",
      Mint60: "#4f7307",
      Mint50: "#6a990a",
      Mint40: "#84c00c",
      Mint30: "#afda58",
      Mint20: "#dff0bc",
      Mint10: "#eff8de",
    },
    Yellow: {
      Yellow70: "#291e00",
      Yellow60: "#664b00",
      Yellow50: "#b88700",
      Yellow40: "#ffbe0a",
      Yellow30: "#ffd35c",
      Yellow20: "#ffe499",
      Yellow10: "#fff1cc",
    },
    Orange: {
      Orange70: "#381d05",
      Orange60: "#713a0a",
      Orange50: "#964d0d",
      Orange40: "#eb7e1e",
      Orange30: "#f2a969",
      Orange20: "#f8d4b4",
      Orange10: "#fce9d9",
    },
    Red: {
      Red70: "#340909",
      Red60: "#681313",
      Red50: "#9c1c1c",
      Red40: "#dd4141",
      Red30: "#e67575",
      Red20: "#f0a8a8",
      Red10: "#f9dcdc",
    },
  },
};
