import { styled } from "~src/designSystem/theme";
import {
  contentColorStorybookArgs,
  contentColorVariants,
  typographyStorybookArgs,
  typographyVariants,
} from "~src/designSystem/theme/variants";
import { castToStitchesStoryComponent } from "~src/storybook/utils";

export const Text = styled("span", contentColorVariants, typographyVariants, {
  // Reset
  fontStyle: "normal",
  textTransform: "none",
  textDecoration: "none",
  textIndent: "0",
  letterSpacing: "0",

  variants: {
    // TODO(md): Fill out & document.
    whitespace: {
      nowrap: {
        "white-space": "nowrap",
      },
    },
  },
});

export const StorybookText = castToStitchesStoryComponent(Text);

export const StorybookTextArgs = {
  ...typographyStorybookArgs,
  ...contentColorStorybookArgs,
  children: {
    description: "The text content to display.",
  },
};
