// eslint-disable-next-line import/no-restricted-paths
import { colorPalette as cp } from "./colorPalette";
import { IPipeComponents, IPipeTheme } from "./darkVariant";
import { colorPalette as oldCp } from "./oldColorPalette";
import { textStyles } from "./textStyles";

const components: IPipeComponents = {
  API: {
    RightColumn: {
      background: cp.Neutrals.Light[100],
    },
  },
  Alert: {
    IconColor: {
      danger: cp.Other.Red.Red40,
      info: cp.Other.Blue.Blue40,
      success: cp.Other.Green.Green40,
      warning: cp.Other.Orange.Orange40,
    },
    background: cp.Neutrals.Light[300],
    boxshadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.08)",
    description: cp.Neutrals.Light[600],
    title: cp.Neutrals.Light[900],
  },

  App: {
    text: cp.Neutrals.Light[900],
  },
  // Needs design review
  AreaChart: {
    axis: {
      stroke: cp.Neutrals.Light[600],
    },
    grid: {
      stroke: cp.Neutrals.Light[600],
    },
    line: {
      stroke: cp.Other.Blue.Blue40,
    },
    linearGradient: {
      from: cp.Other.Blue.Blue40,
      to: cp.Other.Blue.Blue40,
    },
    tooltip: {
      circleInner: {
        fill: cp.Other.Blue.Blue40,
        stroke: cp.Neutrals.Light.Black,
      },
      circleOuter: {
        fill: cp.Other.Blue.Blue40,
        stroke: cp.Other.Blue.Blue40,
      },
      heading: {
        color: cp.Neutrals.Light.Black,
        font: {
          size: "16px",
          weight: 700,
        },
      },
      line: {
        stroke: cp.Other.Blue.Blue10,
      },
      subHeading: {
        color: cp.Neutrals.Light[600],
        font: {
          size: "13px",
          weight: 700,
        },
      },
    },
  },

  AssetRating: {
    A: cp.Other.Purple.Purple20,
    AA: cp.Other.Purple.Purple30,
    AAA: cp.Other.Purple.Purple40,
    B: cp.Other.Teal.Teal20,
    BB: cp.Other.Teal.Teal40,
    BBB: cp.Other.Teal.Teal50,
    C: cp.Other.Yellow.Yellow30,
    CC: cp.Other.Yellow.Yellow40,
    CCC: cp.Other.Yellow.Yellow50,
  },

  Avatar: {
    StatusDot: {
      green: cp.Other.Green.Green40,
      orange: cp.Other.Orange.Orange40,
      red: cp.Other.Red.Red40,
    },
  },

  BackButton: {
    hover: oldCp.neutral600,
  },

  BarChart: {
    fill: cp.Semantic.Dark.Action[400],
    stroke: cp.Semantic.Light.Action[400],
  },

  BeingReviewed: {
    descriptionTextColor: cp.Neutrals.Light[800],
  },

  Box: {
    accent: {
      background: "#ffffff",
    },
    background: "#ffffff",
    border: {
      active: oldCp.blue800,
      default: "#E6E6E6",
      hover: oldCp.blue500,
      modified: oldCp.yellow500,
    },
    boxshadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.08)",
    boxshadowhover: "0px 4px 8px 0px rgba(0, 0, 0, 0.14)",
    new: {
      background: oldCp.neutral800,
      hover: {
        background: oldCp.neutral700,
      },
    },
  },

  Button: {
    primary: {
      background: {
        active: "#4d72ba",
        default: "#5b81cb",
        disabled: "#e4e4e4",
        hover: "#94b0e5",
      },
      loading: "#fff",
      text: {
        active: "#fff",
        default: "#fff",
        disabled: "#C6CBCF",
        hover: "#fff",
      },
    },
    secondary: {
      background: {
        active: "#eaeaea",
        default: "#fff",
        disabled: "#e4e4e4",
        hover: "#f5f5f5",
      },
      loading: "#000",
      text: {
        active: "#656565",
        default: "#656565",
        disabled: "#a4a4a4",
        hover: "#656565",
      },
    },
  },
  ButtonAria: {
    danger: {
      background: {
        active: cp.Semantic.Light.Negative[400],
        default: cp.Semantic.Light.Negative[400],
        disabled: cp.Neutrals.Dark[400],
        hover: cp.Semantic.Light.Negative[500],
      },
      border: {
        active: "transparent",
        default: "transparent",
        disabled: "transparent",
        hover: "transparent",
      },
      color: {
        active: cp.Neutrals.Dark.White,
        default: cp.Neutrals.Dark.White,
        disabled: cp.Neutrals.Dark[700],
        hover: cp.Neutrals.Dark.White,
      },
    },
    flat: {
      background: {
        active: cp.Neutrals.Light[100],
        default: cp.Neutrals.Light[100],
        disabled: cp.Neutrals.Light[400],
        hover: cp.Neutrals.Light[200],
      },
      border: {
        active: "transparent",
        default: "transparent",
        disabled: "transparent",
        hover: "transparent",
      },
      color: {
        active: cp.Neutrals.Light[900],
        default: cp.Neutrals.Light[900],
        disabled: cp.Neutrals.Dark.White,
        hover: cp.Neutrals.Light[900],
      },
    },
    outline: {
      background: {
        active: "transparent",
        default: "transparent",
        disabled: "transparent",
        hover: "transparent",
      },
      border: {
        active: cp.Neutrals.Dark[300],
        default: cp.Neutrals.Dark[400],
        disabled: cp.Neutrals.Dark[300],
        hover: cp.Neutrals.Dark[500],
      },
      color: {
        active: cp.Neutrals.Dark.White,
        default: cp.Neutrals.Dark.White,
        disabled: cp.Neutrals.Dark[600],
        hover: cp.Neutrals.Dark.White,
      },
    },
    primary: {
      background: {
        active: cp.Semantic.Light.Action[400],
        default: cp.Semantic.Light.Action[400],
        disabled: cp.Neutrals.Light[400],
        hover: cp.Semantic.Light.Action[500],
      },
      border: {
        active: "transparent",
        default: "transparent",
        disabled: "transparent",
        hover: "transparent",
      },
      color: {
        active: cp.Neutrals.Dark.White,
        default: cp.Neutrals.Dark.White,
        disabled: cp.Neutrals.Dark.White,
        hover: cp.Neutrals.Dark.White,
      },
    },
    secondary: {
      background: {
        active: cp.Neutrals.Dark[300],
        default: cp.Neutrals.Dark[300],
        disabled: cp.Neutrals.Dark[400],
        hover: cp.Neutrals.Dark[400],
      },
      border: {
        active: "transparent",
        default: "transparent",
        disabled: "transparent",
        hover: "transparent",
      },
      color: {
        active: cp.Neutrals.Dark.White,
        default: cp.Neutrals.Dark.White,
        disabled: cp.Neutrals.Dark[300],
        hover: cp.Neutrals.Dark.White,
      },
    },
    treasuryDanger: {
      background: {
        active: "#DF3E3E",
        default: "#DF3E3E",
        disabled: cp.Neutrals.Dark[400],
        hover: cp.Semantic.Light.Negative[500],
      },
      border: {
        active: "none",
        default: "none",
        disabled: "none",
        hover: "none",
      },
      color: {
        active: cp.Neutrals.Dark.White,
        default: cp.Neutrals.Dark.White,
        disabled: cp.Neutrals.Dark[700],
        hover: cp.Neutrals.Dark.White,
      },
    },
    treasurySuccess: {
      background: {
        active: "#27A75A",
        default: "#27A75A",
        disabled: cp.Neutrals.Dark[400],
        hover: "#22924f",
      },
      border: {
        active: "none",
        default: "none",
        disabled: "none",
        hover: "none",
      },
      color: {
        active: cp.Neutrals.Dark.White,
        default: cp.Neutrals.Dark.White,
        disabled: cp.Neutrals.Dark[700],
        hover: cp.Neutrals.Dark.White,
      },
    },
  },
  CSVManager: {
    FilesWrapper: {
      border: {
        color: cp.Neutrals.Light[300],
      },
    },
  },
  Card: {
    Base: {
      activeBackground: cp.Neutrals.Dark[300],
      border: cp.Neutrals.Dark[300],
    },
    DataSource: {
      Body: {
        text: cp.Neutrals.Light[600],
      },
      StatusTag: {
        Label: {
          color: {
            default: cp.Neutrals.Light[700],
            disconnected: cp.Semantic.Light.Warning[400],
          },
          font: {
            lineHeight: "16px",
            size: "12px",
            weight: 500,
          },
        },
        LoadingSpinner: {
          color: cp.Neutrals.Light[700],
        },
        background: {
          default: cp.Neutrals.Light[200],
          disconnected: cp.Other.Yellow.Yellow70,
        },
      },
    },
    Metric: {
      AddSource: {
        text: cp.Semantic.Light.Action[400],
      },
      Delta: {
        percent: cp.Neutrals.Dark[700],
      },
      Header: {
        text: cp.Neutrals.Dark[700],
      },
      Value: {
        empty: cp.Neutrals.Dark[600],
        present: cp.Neutrals.Light.White,
      },
    },
    Todo: {
      Body: {
        text: cp.Neutrals.Dark[600],
      },
      Header: {
        activeText: cp.Neutrals.Light.White,
        text: cp.Neutrals.Dark[700],
      },
    },
  },

  Changelog: {
    Body: {
      text: cp.Neutrals.Light[800],
    },
    PseudoWindow: {
      background: cp.Neutrals.Light[50],
      border: cp.Neutrals.Light[400],
    },
    Title: {
      text: cp.Neutrals.Light[900],
    },
  },
  Chart: {
    tooltipshadowcolor: "#c4c4c4",
  },

  Checkbox: {
    checked: {
      background: {
        normal: cp.Semantic.Light.Action[400],
      },
      border: {
        normal: cp.Semantic.Light.Action[400],
      },
      check: {
        normal: cp.Neutrals.Light.White,
      },
    },
    unchecked: {
      background: {
        disabled: cp.Neutrals.Light[200],
        hover: cp.Neutrals.Light[200],
        normal: cp.Neutrals.Light[200],
      },
      border: {
        disabled: cp.Neutrals.Light[200],
        hover: cp.Neutrals.Light[500],
        normal: cp.Neutrals.Light[400],
      },
    },
  },

  CircleStatusTag: {
    background: cp.Neutrals.Light[300],
  },

  Code: {
    Border: {
      color: cp.Neutrals.Light[400],
      left: {
        color: cp.Other.Blue.Blue40,
      },
    },
    backgroundColor: cp.Neutrals.Light[100],
    color: cp.Other.Blue.Blue60,
  },

  // TODO: light colors
  ConnectYourData: {
    descriptionTextColor: cp.Neutrals.Light[800],
  },

  // TODO: light colors
  ConnectionBadge: {
    Label: {
      color: {
        default: cp.Neutrals.Light[700],
        warning: cp.Semantic.Light.Warning[400],
      },
      font: {
        lineHeight: "16px",
        size: "12px",
        weight: 500,
      },
    },
    Wrapper: {
      background: {
        default: cp.Neutrals.Light[50],
        hover: cp.Neutrals.Light[100],
      },
      border: cp.Neutrals.Light[300],
    },
  },

  // TODO: light colors
  ConnectionStatusBadge: {
    Label: {
      color: {
        default: cp.Neutrals.Dark[700],
        warning: cp.Semantic.Light.Warning[400],
      },
      font: {
        lineHeight: "16px",
        size: "12px",
        weight: 500,
      },
    },
    Wrapper: {
      background: {
        neutral: {
          default: cp.Neutrals.Dark[200],
        },
        warning: {
          default: cp.Semantic.Dark.Warning[100],
        },
      },
      border: {
        default: cp.Neutrals.Dark[300],
        hover: cp.Semantic.Dark.Warning[300],
      },
    },
  },

  CustomerOverviewTable: {
    Fractional: {
      background: cp.Semantic.Light.Action[400],
    },
    Full: {
      background: cp.Neutrals.Light[400],
    },
    Subtitle: {
      color: cp.Neutrals.Light[700],
    },
    color: cp.Neutrals.Light[800],
  },

  DataSources: {
    DataSourceRow: {
      ActionButton: {
        color: cp.Other.Blue.Blue40,
      },
    },
    SubdomainInputContainer: {
      Input: {
        color: cp.Neutrals.Light[900],
      },
    },
  },

  DatePicker: {
    background: cp.Neutrals.Light[200],
    backgroundHover: cp.Neutrals.Light[400],
    border: cp.Neutrals.Light[400],
    month: cp.Neutrals.Light[800],
    text: cp.Neutrals.Light[900],
    weekHeader: cp.Neutrals.Light[600],
  },

  Declined: {
    descriptionTextColor: cp.Neutrals.Light[800],
  },

  Demos: {
    FormError: {
      text: cp.Semantic.Light.Negative[400],
    },
  },

  // TODO: light colors
  Dialog: {
    Header: {
      Title: {
        color: cp.Neutrals.Light[800],
        font: {
          size: "13px",
          weight: 500,
        },
      },
      background: cp.Neutrals.Light[50],
      border: cp.Neutrals.Light[200],
    },
    Overlay: {
      background: cp.Neutrals.Light[50],
    },
    SideColumn: {
      ActionLabel: {
        color: cp.Neutrals.Light[500],
        font: {
          lineHeight: "20px",
          size: "13px",
          weight: 500,
        },
      },
      Header: {
        background: cp.Neutrals.Light[100],
      },
      background: cp.Neutrals.Light[100],
    },
  },

  Divider: {
    box: cp.Neutrals.Light[500],
    default: cp.Neutrals.Light[200],
  },

  DropdownMenu: {
    Item: {
      background: {
        default: cp.Neutrals.Light[200],
        hover: cp.Neutrals.Light[300],
        selected: cp.Neutrals.Light[300],
      },
    },
  },

  Dropzone: {
    background: {
      active: cp.Neutrals.Light[100],
      default: cp.Neutrals.Light[50],
      hover: cp.Neutrals.Light[100],
      reject: cp.Semantic.Dark.Negative[400],
    },
    border: {
      accept: cp.Semantic.Dark.Action[400],
      active: cp.Semantic.Dark.Action[400],
      default: cp.Neutrals.Light[600],
      hover: cp.Semantic.Dark.Action[400],
      reject: cp.Semantic.Dark.Negative[400],
    },
    text: {
      default: cp.Neutrals.Light[600],
      hover: cp.Semantic.Dark.Action[400],
    },
  },

  EncryptionMessage: {
    text: cp.Neutrals.Light[600],
  },

  EntityTile: {
    Placeholder: {
      background: cp.Neutrals.Light[300],
      text: cp.Neutrals.Light[600],
    },
    Subtitle: {
      text: cp.Neutrals.Light[600],
    },
    Title: {
      text: cp.Neutrals.Light[800],
    },
  },
  Error: {
    icon: cp.Neutrals.Dark[500],
    text: cp.Neutrals.Dark[600],
  },

  FinanceCenter: {
    DownloadIcon: {
      color: cp.Neutrals.Light[700],
    },
    PaymentList: {
      Summary: {
        text: cp.Neutrals.Light[700],
      },
    },
    SummaryChart: {
      border: cp.Neutrals.Light[400],
    },
  },
  HelpWidget: {
    background: {
      default: cp.Neutrals.Light[100],
      hover: cp.Neutrals.Light[200],
    },
    icon: cp.Neutrals.Light[500],
  },

  InlineNav: {
    background: {
      hover: cp.Neutrals.Light[200],
      selected: cp.Neutrals.Light[100],
    },
    text: {
      default: cp.Neutrals.Light[700],
      hover: cp.Neutrals.Light[900],
      selected: cp.Neutrals.Light[900],
    },
  },

  Input: {
    background: {
      active: cp.Neutrals.Light.White,
      default: "#ffffff",
      disabled: "#f2f2f2",
      hover: "#eee",
    },
    border: {
      active: "#5b81cb",
      default: "#e9e9e9",
      disabled: cp.Neutrals.Light[300],
      error: cp.Semantic.Dark.Negative[400],
      hover: cp.Neutrals.Light[400],
      modified: "#f8c100",
    },
    icon: {
      default: cp.Neutrals.Light[600],
      error: cp.Semantic.Dark.Negative[400],
    },
    label: {
      default: cp.Neutrals.Light[600],
      error: cp.Semantic.Dark.Negative[400],
    },
    text: {
      default: "#0d0d0d",
      disabled: cp.Neutrals.Light[500],
      placeholder: "#dfdfdf",
    },
  },
  InvestorOverviewCards: {
    AssetAmount: {
      text: cp.Neutrals.Light[900],
    },
    Header: {
      text: cp.Neutrals.Light[600],
    },
    Label: {
      text: cp.Neutrals.Light[700],
    },
    PaymentSchedule: {
      Payment: {
        amount: cp.Neutrals.Light[900],
        date: cp.Neutrals.Light[700],
        title: cp.Neutrals.Light[600],
      },
      VerticalRule: {
        color: cp.Neutrals.Light[300],
      },
    },
    Value: {
      text: cp.Neutrals.Light[900],
    },
  },
  InvestorSupportCard: {
    ContactSupport: {
      text: cp.Neutrals.Dark[600],
    },
    ManagerDetails: {
      icon: cp.Neutrals.Dark[600],
      text: cp.Other.Blue.Blue30,
    },
    ManagerName: {
      text: cp.Neutrals.Dark[900],
    },
    SectionHeader: {
      text: cp.Neutrals.Dark[600],
    },
    SupportHours: {
      text: cp.Neutrals.Dark[600],
    },
  },
  InvestorTrading: {
    AllMarkets: {
      text: cp.Neutrals.Dark[800],
    },
    LastUpdated: {
      description: cp.Neutrals.Dark[600],
      time: cp.Neutrals.Dark[800],
    },
    MarketBox: {
      ChartLegend: {
        mutedColor: cp.Neutrals.Dark[300],
        mutedText: cp.Neutrals.Dark[400],
        text: cp.Neutrals.Dark[700],
      },
      LastIRR: {
        quarterLabel: cp.Neutrals.Dark[600],
        quarterValue: cp.Neutrals.Dark[700],
        volumeLabel: cp.Neutrals.Dark[700],
        volumeValue: cp.Neutrals.Dark[800],
      },
      Title: {
        text: cp.Neutrals.Dark[800],
      },
    },
  },
  InviteTeammateModal: {
    Form: {
      label: cp.Neutrals.Light[900],
    },
  },
  KeyValueList: {
    Key: {
      text: cp.Neutrals.Light[600],
    },
    Value: {
      text: cp.Neutrals.Light[900],
    },
  },
  LetterIcon: {
    text: cp.Neutrals.Light.Black,
  },
  LineChart: {
    axis: {
      label: cp.Neutrals.Dark[400],
      stroke: cp.Neutrals.Dark[400],
    },
    legend: {
      label: cp.Neutrals.Dark[800],
    },
  },

  Link: {
    text: {
      default: cp.Semantic.Light.Action[400],
      hover: cp.Semantic.Light.Action[500],
    },
  },

  // TODO: Light mode colors.
  ListBox: {
    font: {
      lineHeight: "20px",
      secondarySize: "12px",
      size: "13px",
      weight: 500,
    },
    headerLabelBgColor: cp.Neutrals.Light[100],
    headerLabelTextColor: cp.Neutrals.Light[800],
    icon: {
      backgroundColor: cp.Neutrals.Light[100],
      backgroundSize: "40px",
    },
    itemActiveBgColor: cp.Neutrals.Light[400],
    itemBgColor: cp.Neutrals.Light[200],
    itemBgColorHover: cp.Neutrals.Light[300],
    itemDescriptionTextColor: cp.Neutrals.Light[600],
    itemDividerBorderColor: cp.Neutrals.Light[300],
    itemLabelTextColor: cp.Neutrals.Light[800],
  },

  Loader: {
    Dots: {
      background: cp.Neutrals.Light[800],
      primary: cp.Other.Blue.Blue40,
    },
    Gradient: {
      background: "#fafafa",
      foreground: "#f0f0f0",
    },
  },
  LoadingSpinner: {
    DefaultColor: cp.Semantic.Light.Action[400],
  },

  LowEndExperiment: {
    Choose: {
      Background: {
        default: cp.Neutrals.Light[100],
        hover: cp.Neutrals.Light[200],
      },
      Loading: {
        color: cp.Neutrals.Light[700],
      },
    },
  },

  ManualOp: {
    Directory: {
      Entry: {
        background: cp.Neutrals.Light[200],
      },
    },
  },

  MiniHeader: {
    border: cp.Neutrals.Light[300],
    title: cp.Neutrals.Light[900],
  },

  /* TODO(md): The modals need to be re-done, why are the colors named this way?? */
  Modal: {
    activeText: cp.Neutrals.Dark.Black,
    background: cp.Neutrals.Light[100],
    border: cp.Neutrals.Light[300],
    divider: cp.Neutrals.Light[400],
    foreground: cp.Neutrals.Light[200],
    label: cp.Neutrals.Light[600],
    link: cp.Neutrals.Light[700],
    text: cp.Neutrals.Light[700],
    title: cp.Neutrals.Light[900],
  },

  ModalPipe: {
    background: cp.Neutrals.Light[50],
    // TODO(md): We should be using a shadow for this instead of a constant border
    // color. Switch to that once we add the shadow tokens to the application. Work out
    // a usage pattern then.
    border: cp.Neutrals.Light[300],
    description: cp.Neutrals.Light[600],
    logo: cp.Neutrals.Light.Black,
    title: cp.Neutrals.Light[900],
  },

  NavBar: {
    Header: {
      background: "#fafcff",
      logo: "#000",
    },
    ProfileMenu: {
      background: cp.Neutrals.Light[100],
    },
    Texticon: {
      active: "#0d0d0d",
      background: {
        default: cp.Neutrals.Light[100],
        hover: cp.Neutrals.Light[200],
      },
      inactive: "#818181",
    },
  },

  Notifications: {
    Background: {
      color: cp.Neutrals.Light[100],
    },
    CaughtUp: {
      icon: cp.Neutrals.Light[500],
      text: cp.Neutrals.Light[800],
    },
    Header: {
      color: cp.Neutrals.Light[800],
    },
    NotificationItem: {
      content: cp.Neutrals.Light[800],
      cta: cp.Neutrals.Light[600],
      hoverBg: cp.Neutrals.Light[200],
      icon: cp.Neutrals.Light[500],
    },
    RedDot: {
      color: cp.Other.Red.Red40,
    },
  },

  NumberField: {
    border: cp.Neutrals.Light[200],
  },

  OrderBox: {
    Container: {
      // Colors from Figma, werent present in cp.
      Left: "#27282B",
      Right: "#2C2E32",
    },
    PayoutInput: "2C2E32",
    Row: {
      background: {
        hover: cp.Neutrals.Light[200],
      },
    },

    SelectPayoutMethod: {
      Selection: {
        background: {
          active: cp.Neutrals.Light[200],
          hover: cp.Neutrals.Light[200],
        },
      },
    },

    Slider: {
      alert: {
        error: cp.Semantic.Dark.Negative[400],
      },
      percentage: cp.Neutrals.Dark[400],
    },
    // TODO: This should just use button styles.
    SubmitButton: {
      background: {
        default: cp.Semantic.Dark.Positive[400],
        disabled: cp.Neutrals.Light[200],
        hover: cp.Semantic.Dark.Positive[500],
      },
      border: {
        disabled: cp.Neutrals.Light[300],
      },
      text: {
        default: cp.Neutrals.Light[900],
        disabled: cp.Neutrals.Light[400],
      },
    },
    SuccessfulTrade: {
      label: {
        default: cp.Neutrals.Light[700],
      },
    },
    background: cp.Neutrals.Light[100],
    boxShadow: "0px 4px 11px rgba(0, 0, 0, 0.25)",
  },

  PasswordModal: {
    H2: {
      color: cp.Neutrals.Light[900],
    },
    Instructions: {
      text: cp.Neutrals.Light[600],
    },
    ResetPasswordLink: {
      color: {
        default: cp.Neutrals.Light[600],
        hover: cp.Neutrals.Light[900],
      },
    },
  },

  PaymentSchedule: {
    axis: cp.Neutrals.Light[400],
    bar: cp.Neutrals.Light[500],
    flyout: cp.Neutrals.Light[200],
    flyoutText: cp.Neutrals.Light[900],
    stroke: cp.Neutrals.Light[200],
    tick: cp.Neutrals.Light[200],
    tickLabel: cp.Neutrals.Light[700],
  },
  PortfolioAssetsTable: {
    AssetCell: {
      background: cp.Neutrals.Light[100],
      text: cp.Neutrals.Light[900],
    },
    AssetHeaderCell: {
      text: cp.Neutrals.Light[600],
    },
    TableBorder: {
      color: cp.Neutrals.Light[300],
    },
    TradeCell: {
      text: cp.Neutrals.Light[600],
    },
    TradeHeaderCell: {
      text: cp.Neutrals.Light[600],
    },
    TradeLine: {
      color: cp.Neutrals.Light[400],
    },
  },
  ProfileMenu: {
    Link: {
      background: {
        hover: cp.Neutrals.Light[200],
      },
      icon: cp.Neutrals.Light[600],
      text: cp.Neutrals.Light[900],
    },
    Who: {
      text: cp.Neutrals.Light[800],
    },
    divider: cp.Neutrals.Light[300],
  },

  ProgressBar: {
    bar: cp.Neutrals.Light[400],
    progress: cp.Semantic.Dark.Action[400],
  },

  Radio: {
    circleInnerColor: cp.Neutrals.Light[100],
    circleInnerColorHover: cp.Neutrals.Light[100],
    circleInnerColorSelected: cp.Neutrals.Light[900],
    circleInnerColorSelectedHover: cp.Neutrals.Light[900],
    circleOuterColor: cp.Neutrals.Light[300],
    circleOuterColorHover: cp.Neutrals.Light[400],
    circleOuterColorSelected: cp.Other.Blue.Blue40,
    circleOuterColorSelectedHover: cp.Other.Blue.Blue30,
    font: {
      lineHeight: "20px",
      secondarySize: "12px",
      size: "13px",
      weight: 500,
    },
    primaryTextColor: cp.Neutrals.Light[800],
    rowBgColor: cp.Neutrals.Light[200],
    rowBgColorHover: cp.Neutrals.Light[300],
    secondaryTextColor: cp.Neutrals.Light[600],
    valueTextColor: cp.Neutrals.Light[800],
  },

  RadioGroup: {
    font: {
      lineHeight: "20px",
      size: "13px",
      weight: 500,
    },
    headerBgColor: oldCp.neutral900,
    headerTextColor: oldCp.neutral200,
  },
  ReleaseModal: {
    background: cp.Neutrals.Light[200],
    border: cp.Neutrals.Light[400],
    title: cp.Neutrals.Light[900],
  },
  Scrollbar: {
    // TODO(ASAP): I don't know what color we should use here.
    hover: cp.Neutrals.Light[300],
    normal: cp.Neutrals.Light[400],
  },

  SegmentedControl: {
    background: cp.Neutrals.Dark[100],
    color: cp.Neutrals.Dark.White,
    hover: {
      background: cp.Neutrals.Dark[300],
    },
    selected: {
      background: cp.Neutrals.Dark[400],
    },
  },

  Select: {
    background: {
      active: cp.Neutrals.Light.White,
      default: cp.Neutrals.Light.White,
      disabled: cp.Neutrals.Light[50],
      hover: cp.Neutrals.Light[300],
    },
    border: {
      active: "#5b81cb",
      default: "#e9e9e9",
      disabled: cp.Neutrals.Light[300],
      error: cp.Semantic.Light.Negative[400],
      hover: cp.Neutrals.Light[500],
    },
    color: {
      default: cp.Neutrals.Light[900],
      disabled: cp.Neutrals.Light[400],
      empty: cp.Neutrals.Light[600],
      error: cp.Semantic.Light.Negative[400],
      hover: cp.Neutrals.Light[700],
    },
    title: cp.Neutrals.Light[500],
  },

  SelectableCard: {
    background: {
      default: cp.Neutrals.Light[200],
    },
    boxShadow: {
      default: "0px 6px 8px 0px rgba(0, 0, 0, 0.12)",
      hover: "0px 6px 8px 0px rgba(0, 0, 0, 0.18)",
    },
  },
  SideColumn: {
    Details: {
      body: {
        item: {
          font: {
            size: "12px",
            weight: 400,
          },
          text: {
            color: cp.Neutrals.Light.Black,
          },
        },
        label: {
          font: {
            size: "12px",
            weight: 400,
          },
          text: {
            color: cp.Neutrals.Light[600],
          },
        },
      },
      header: {
        border: {
          color: cp.Neutrals.Light[400],
        },
        font: {
          size: "13px",
          weight: 600,
        },
        text: {
          color: cp.Neutrals.Light.Black,
        },
      },
    },
    Header: {
      border: cp.Neutrals.Light[300],
      text: cp.Neutrals.Light[900],
    },
    Wrapper: {
      background: {
        color: cp.Neutrals.Light[100],
      },
      border: {
        color: cp.Neutrals.Light[300],
      },
    },
  },

  Sidebar: {
    ListItem: {
      itemCount: {
        active: cp.Other.Blue.Blue30,
        hover: cp.Other.Blue.Blue30,
        inactive: cp.Neutrals.Light[600],
      },
      text: {
        active: cp.Neutrals.Light[800],
        hover: cp.Neutrals.Light[800],
        inactive: cp.Neutrals.Light[700],
      },
    },
    SectionHeader: {
      text: "#848484",
    },
    SubnavLink: {
      background: {
        active: "#f4f6fa",
        hover: "#ebeef1",
      },
      text: {
        active: "#0d0d0d",
        inactive: "#717273",
      },
    },
  },

  Slider: {
    Background: {
      disabled: cp.Neutrals.Dark[300],
      selected: cp.Semantic.Dark.Action[400],
      unselected: cp.Neutrals.Dark[400],
    },
    Label: {
      text: cp.Neutrals.Light[800],
    },
    Thumb: {
      disabled: cp.Neutrals.Dark[400],
      focused: cp.Semantic.Light.Action[500],
      selected: cp.Neutrals.Light[900],
      unselected: cp.Neutrals.Light[800],
    },
    Value: {
      text: cp.Neutrals.Light[900],
    },
  },
  // TODO: light colors
  StackedAreaChart: {
    XAxis: {
      tickLabelColor: cp.Neutrals.Light[700],
      tickStroke: cp.Neutrals.Light[900],
    },
    YAxis: {
      tickLabelColor: cp.Neutrals.Light[700],
      tickStroke: cp.Neutrals.Light[900],
    },
  },

  StackedBarChart: {
    axis: {
      label: cp.Neutrals.Dark[400],
      stroke: cp.Neutrals.Dark[400],
    },
    bars: {
      bottom: cp.Other.Blue.Blue40,
      top: cp.Other.Teal.Teal40,
    },
    font: {
      color: cp.Neutrals.Light[600],
      size: "10px",
    },
    legend: {
      label: cp.Neutrals.Dark[800],
    },
    tooltip: {
      bar: cp.Neutrals.Dark[100],
      stroke: cp.Neutrals.Dark[200],
      title: cp.Neutrals.Dark[700],
      value: cp.Neutrals.Dark[800],
    },
  },

  StatusTag: {
    base: {
      blue: "#dfe7f6",
      green: "#d4f2e4",
      neutral: "#dbdfea",
      orange: "#f7e6d5",
      purple: "#eae7f8",
      red: "#f8d9d9",
      teal: "#c4c4c4",
      yellow: "#fff7df",
    },
    text: {
      blue: "#47649e",
      green: "#28bf7a",
      neutral: "#404040",
      orange: "#a36220",
      purple: "#735dd0",
      red: "#a63131",
      teal: "#c4c4c4",
      yellow: "#bfa147",
    },
  },
  Switch: {
    disabled: {
      background: cp.Neutrals.Light[700],
      circle: cp.Neutrals.Light[500],
    },
    focusRing: {
      color: cp.Semantic.Light.Action[400],
    },
    loadingOff: {
      background: cp.Neutrals.Light[300],
      circle: cp.Neutrals.Light[800],
    },
    loadingOn: {
      background: cp.Semantic.Light.Action[300],
      circle: cp.Neutrals.Light[800],
    },
    off: {
      background: cp.Neutrals.Light[300],
      circle: cp.Neutrals.Light.White,
    },
    on: {
      background: cp.Semantic.Light.Action[400],
      circle: cp.Neutrals.Light.White,
    },
  },
  TOTPModal: {
    Error: {
      icon: cp.Semantic.Dark.Negative[400],
      text: cp.Neutrals.Light[600],
    },
    Instructions: {
      text: cp.Neutrals.Light[600],
    },
    RecoveryCodeCallout: {
      color: {
        default: cp.Neutrals.Light[600],
        hover: cp.Neutrals.Light[900],
      },
    },
  },

  TabContainer: {
    background: {
      default: cp.Neutrals.Light[200],
      selected: cp.Neutrals.Light[100],
    },
    text: {
      default: cp.Neutrals.Light[600],
      selected: cp.Neutrals.Light[700],
    },
  },

  TabSection: {
    TabButton: {
      border: {
        active: cp.Neutrals.Light[900],
      },
      text: {
        active: cp.Neutrals.Light[900],
        default: cp.Neutrals.Light[700],
      },
    },
    border: cp.Neutrals.Light[100],
  },

  Table: {
    EmptyState: {
      text: cp.Neutrals.Light[700],
    },
    Header: {
      background: cp.Neutrals.Light[100],
      text: {
        default: cp.Neutrals.Light[600],
        hover: cp.Neutrals.Light[700],
      },
    },
    Row: {
      background: {
        hover: cp.Neutrals.Light[100],
      },
      border: cp.Neutrals.Light[100],
      text: {
        default: cp.Neutrals.Light[900],
      },
    },
    rowHeight: 44,
    text: cp.Neutrals.Light.Black,
  },
  Text: {
    button: "#fff",
    default: "#3c3c3c",
    error: "#DD4141",
    link: "#5e85d2",
    muted: "#727272",
    // TODO: need to get rid of this
    oldGradient:
      "linear-gradient(90deg, #B265ED calc(-1.92% / 2), #BC6CD4 calc(6.4% / 2), #C975B4 calc(26.65% / 2), #E28676 calc(59.01% / 2), #F1914F calc(92.12% / 2), #C975B4 calc(100% - 26.65% / 2), #BC6CD4 calc(100% - 6.4% / 2), #B265ED calc(100% - -1.92% / 2))",
    subtitle: "#7B88A3",
    success: "#5e9850",
    title: "#0a0a0a",
    warning: oldCp.orange500,
  },

  TextField: {
    background: {
      active: cp.Neutrals.Light.White,
      default: cp.Neutrals.Light[200],
      disabled: cp.Neutrals.Light[50],
      hover: cp.Neutrals.Light[300],
    },
    border: {
      active: cp.Semantic.Light.Action[400],
      default: cp.Neutrals.Light[400],
      disabled: cp.Neutrals.Light[300],
      error: cp.Semantic.Light.Negative[400],
      hover: cp.Neutrals.Light[500],
    },
    color: {
      active: cp.Neutrals.Light[900],
      default: cp.Neutrals.Light[500],
      disabled: cp.Neutrals.Light[500],
      hover: cp.Neutrals.Light[700],
      placeholder: cp.Neutrals.Light[600],
    },
    description: cp.Neutrals.Light[500],
    errorMessage: cp.Semantic.Light.Negative[400],
    iconColor: cp.Neutrals.Light[800],
    label: cp.Neutrals.Light[600],
  },
  Toast: {
    Background: cp.Neutrals.Light[300],
    Dismiss: cp.Neutrals.Light[500],
    Icon: {
      error: cp.Semantic.Light.Negative[400],
      info: cp.Semantic.Light.Action[400],
      success: cp.Semantic.Light.Positive[400],
    },
    Text: cp.Neutrals.Light[800],
  },
  Tooltip: {
    background: cp.Neutrals.Light[300],
    text: cp.Neutrals.Light[900],
  },
  Treasury: {
    ActivityLog: {
      OnHover: {
        default: cp.Neutrals.Dark[600],
        selected: cp.Neutrals.Dark.White,
      },
      StatusTag: {
        background: cp.Neutrals.Dark[300],
        color: cp.Neutrals.Dark[700],
      },
    },
    Chart: {
      Tooltip: {
        font: {
          color: cp.Neutrals.Dark[600],
        },
        line: cp.Neutrals.Dark[600],
      },
    },
    Color: {
      Green: "#27A75A",
      Neutral: cp.Neutrals.Dark[600],
      Red: "#DD4141",
      White: cp.Neutrals.Dark.White,
    },
    MainPane: {
      Header: {
        background: cp.Neutrals.Dark[50],
      },
      background: cp.Neutrals.Dark[50],
    },
    SidePane: {
      Header: {
        background: cp.Neutrals.Dark[300],
      },
      background: cp.Neutrals.Dark[100],
    },
    Waitlist: {
      toast: "#2F2F35",
    },
  },
  Trends: {
    down: cp.Other.Red.Red70,
    up: cp.Other.Green.Green70,
  },
  Welcome: {
    AddSubscriptionsTile: {
      background: cp.Neutrals.Light[300],
      color: cp.Semantic.Dark.Action[400],
    },
    border: {
      default: cp.Other.Blue.Blue40,
      hover: cp.Other.Blue.Blue40,
    },
  },
};

const old = {
  "action": "#5E85D2",
  "action;": "#5E85D2",
  "actionHighlight": "#87A5DE",
  "backgroundStroke": "#eeeeee",
  "base": { background: "#fff", default: "#f9fafc", muted: "#fff" },
  "border": { default: "#dbdfea", muted: "#eceef4" },
  "button": {
    secondary: {
      background: "#FFFFFF",
      border: "#e9ebed",
      hoverBackground: "#f9f9f9",
      text: "#14151A",
    },
  },
  "command-k": {
    "base": "#fff",
    "cursor": "#dfe7f6",
    "option-hover": "#f2f4f8",
  },
  "cyan": "#4EA9BD",
  "danger": "#B63831",
  "danger;": "#B63831",
  "dangerFaded": "rgba(182,56,49,0.11)",
  "expand-side-panel": {
    base: "#616468",
    retract: "rgba(67, 90, 111, 0.3)",
  },
  "functional": {
    background: { default: "#f9fafc" },
    border: { "border-default": "#dbdfea", "border-muted": "#eceef4" },
    icon: { default: "#727272" },
  },
  "goat": {
    background: "#F9F9F9",
    border: "#E9EBED",
    editText: "#656565",
    fieldBackgroundActive: "#FFFFFF",
    foreground: "#E9EBED",
    headerBgHover: "#E9EBED",
    rowHover: "#f3f7ff",
  },
  "goat-table": { "active-row": "#f4f6fb", "header": "#f9fafc", "row": "#fff" },
  "green2": "#27AE60",
  "icon": {
    "default": "#727272",
    "muted": "rgba(67, 90, 111, 0.3)",
    "very-muted": "#fff",
  },
  "keyboard-shortcut": { default: "#616468", muted: "#dedede" },
  "layoutNavbarBackground": "#F9FAFC",
  "modalCloseX": "#2F2F35",
  "navBorder": "#E9EBED",
  "palette": {
    blue: { base: "#5e85d2", dark: "#47649e", lightest: "#eff3fb" },
    green: { base: "#28bf7a", dark: "#1e8f5c", light: "#d4f2e4" },
    orange: { base: "#db8818", dark: "#a36220", light: "#f7e6d5" },
    red: { base: "#dd4141" },
  },
  "progressBarBackground": "#505258",
  "stripesBackground": "#eaeaea",
  "stripesForeground": "#eaeaea",
  "success": "#28BF7A",
  "success ": "#28BF7A",
  "successFaded": "rgba(40,191,122,0.11)",
  "text": {
    "dark": "#ebebec",
    "default": "#404040",
    "emphasized": "#0a0a0a",
    "muted": "#818181",
    "notsomuted_FIXME": "#525252",
    "very-muted": "#a6a6a6",
  },
  "tooltip": { text: "#fff" },
};

export const themeLight: IPipeTheme = {
  activityLog: {
    color: "#727272",
    colorBold: "#3c3c3c",
  },
  bw: {
    transparent: "#c4c4c4",
    white: "#fff",
  },
  components,
  icon: {
    default: "#f2f2f2",
    muted: "rgba(67, 90, 111, 0.3)",
    subtitle: "#727272",
  },
  isDark: false,
  /** @deprecated */
  old,
  permissions: {
    choice: {
      active: "#f4f6fa",
      hover: "#ebeef1",
    },
  },
  textStyles,
  touchable: {
    font: {
      weight: 600,
    },
    text: {
      active: "#5E85D2",
    },
  },
  transition: "all .2s ease",
};
