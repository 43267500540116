// This file is generated by scripts/generate-theme.js. DO NOT EDIT.

/* eslint-disable @typescript-eslint/dot-notation */

export const colorsPrimitive = {
  Vis: {
    "Grey": {
      "10": "#090a0c",
      "20": "#0d0f12",
      "30": "#111418",
      "40": "#1a1f23",
      "45": "#2b333b",
      "50": "#45525f",
      "55": "#566676",
      "60": "#677a8e",
      "65": "#7d8fa1",
      "70": "#acb8c3",
      "75": "#d0d6dd",
      "80": "#e1e5ea",
      "85": "#e1e5ea",
      "90": "#edf0f2",
      "00": "#040506",
    },
    "Magenta Red": {
      "10": "#290a0d",
      "20": "#390e12",
      "30": "#491217",
      "40": "#62181f",
      "45": "#832129",
      "50": "#a32933",
      "55": "#c4313d",
      "60": "#d24b57",
      "65": "#d65c66",
      "70": "#da6c75",
      "75": "#e28d94",
      "80": "#ebadb3",
      "85": "#f3ced1",
      "90": "#f9e7e8",
      "00": "#180608",
    },
    "Red Orange": {
      "10": "#29120a",
      "20": "#39190e",
      "30": "#492012",
      "40": "#622b18",
      "45": "#833921",
      "50": "#a34729",
      "55": "#c45631",
      "60": "#d26d4b",
      "65": "#d67a5c",
      "70": "#da886c",
      "75": "#e2a28d",
      "80": "#ebbdad",
      "85": "#f3d7ce",
      "90": "#f9ebe7",
      "00": "#180b06",
    },
    "Orange": {
      "10": "#291a0a",
      "20": "#39240e",
      "30": "#492e12",
      "40": "#623d18",
      "45": "#835221",
      "50": "#a36629",
      "55": "#c47a31",
      "60": "#d28f4b",
      "65": "#d6995c",
      "70": "#daa36c",
      "75": "#e2b88d",
      "80": "#ebccad",
      "85": "#f3e0ce",
      "90": "#f9f0e7",
      "00": "#180f06",
    },
    "Orange Yellow": {
      "10": "#2e2405",
      "20": "#403207",
      "30": "#534009",
      "40": "#6e560c",
      "45": "#937210",
      "50": "#b88f14",
      "55": "#dcab18",
      "60": "#e9bc35",
      "65": "#ebc247",
      "70": "#edc85a",
      "75": "#f1d47e",
      "80": "#f5e0a3",
      "85": "#f9edc8",
      "90": "#fcf6e3",
      "00": "#1c1503",
    },
    "Yellow Lime": {
      "10": "#21280b",
      "20": "#2e3810",
      "30": "#3b4814",
      "40": "#4e5f1b",
      "45": "#687f24",
      "50": "#839f2d",
      "55": "#9dbf36",
      "60": "#aece50",
      "65": "#b6d260",
      "70": "#bdd770",
      "75": "#cce090",
      "80": "#dae9af",
      "85": "#e9f2cf",
      "90": "#f4f8e7",
      "00": "#141807",
    },
    "Lime Green": {
      "10": "#14250e",
      "20": "#1c3314",
      "30": "#24421a",
      "40": "#305822",
      "45": "#40762e",
      "50": "#509339",
      "55": "#5fb045",
      "60": "#76c05d",
      "65": "#83c66c",
      "70": "#8fcc7b",
      "75": "#a8d798",
      "80": "#c1e2b6",
      "85": "#daeed3",
      "90": "#ecf6e9",
      "00": "#0c1609",
    },
    "Mint Aqua": {
      "10": "#0c2720",
      "20": "#11362d",
      "30": "#16463a",
      "40": "#1d5d4d",
      "45": "#277c67",
      "50": "#319b81",
      "55": "#3bba9a",
      "60": "#54c9ac",
      "65": "#64ceb4",
      "70": "#73d3bb",
      "75": "#92ddca",
      "80": "#b1e7d9",
      "85": "#d0f0e8",
      "90": "#e8f8f4",
      "00": "#071713",
    },
    "Aqua Blue": {
      "10": "#0a2129",
      "20": "#0e2e39",
      "30": "#123c49",
      "40": "#185062",
      "45": "#216a83",
      "50": "#2985a3",
      "55": "#319fc4",
      "60": "#4bb0d2",
      "65": "#5cb8d6",
      "70": "#6cbfda",
      "75": "#8dcde2",
      "80": "#addbeb",
      "85": "#ceeaf3",
      "90": "#e7f4f9",
      "00": "#061418",
    },
    "Blue Violet": {
      "10": "#0a1229",
      "20": "#0e1939",
      "30": "#122049",
      "40": "#182b62",
      "45": "#213983",
      "50": "#2947a3",
      "55": "#3156c4",
      "60": "#4b6dd2",
      "65": "#5c7ad6",
      "70": "#6c88da",
      "75": "#8da2e2",
      "80": "#adbdeb",
      "85": "#ced7f3",
      "90": "#e7ebf9",
      "00": "#060b18",
    },
    "Violet Purple": {
      "10": "#130c27",
      "20": "#1a1136",
      "30": "#221646",
      "40": "#2d1d5d",
      "45": "#3c277c",
      "50": "#4b319b",
      "55": "#5b3bba",
      "60": "#7254c9",
      "65": "#7e64ce",
      "70": "#8b73d3",
      "75": "#a592dd",
      "80": "#bfb1e7",
      "85": "#d8d0f0",
      "90": "#ece8f8",
      "00": "#0b0717",
    },
    "Purple Pink": {
      "10": "#200c27",
      "20": "#2d1136",
      "30": "#3a1646",
      "40": "#4d1d5d",
      "45": "#67277c",
      "50": "#81319b",
      "55": "#9a3bba",
      "60": "#ac54c9",
      "65": "#b464ce",
      "70": "#bb73d3",
      "75": "#ca92dd",
      "80": "#d9b1e7",
      "85": "#e8d0f0",
      "90": "#f4e8f8",
      "00": "#130717",
    },
  },
  Accent: {
    Navy: {
      "10": "#08090d",
      "20": "#0c0d13",
      "30": "#101219",
      "40": "#171b26",
      "45": "#272d3f",
      "50": "#3e4865",
      "55": "#4e5a7e",
      "60": "#5d6c98",
      "65": "#7481aa",
      "70": "#a6afc9",
      "75": "#d9dde8",
      "80": "#eceef3",
      "85": "#f2f4f7",
      "90": "#f9f9fb",
      "00": "#000000",
    },
    Blue: {
      "10": "#0a1229",
      "20": "#0e1939",
      "30": "#122049",
      "40": "#182b62",
      "45": "#213983",
      "50": "#2947a3",
      "55": "#3156c4",
      "60": "#4b6dd2",
      "65": "#5c7ad6",
      "70": "#6c88da",
      "75": "#8da2e2",
      "80": "#adbdeb",
      "85": "#ced7f3",
      "90": "#e7ebf9",
      "00": "#060b18",
    },
    Copper: {
      "10": "#25160e",
      "20": "#331e14",
      "30": "#42271a",
      "40": "#583422",
      "45": "#76462e",
      "50": "#935739",
      "55": "#b06845",
      "60": "#c07e5d",
      "65": "#c68a6c",
      "70": "#cc967b",
      "75": "#d7ad98",
      "80": "#e2c5b6",
      "85": "#eedcd3",
      "90": "#f6ede9",
      "00": "#160d09",
    },
    Indigo: {
      "10": "#111122",
      "20": "#18182f",
      "30": "#1f1f3d",
      "40": "#2a2a51",
      "45": "#37376c",
      "50": "#454587",
      "55": "#5353a2",
      "60": "#6b6bb3",
      "65": "#7878ba",
      "70": "#8686c1",
      "75": "#a1a1ce",
      "80": "#bcbcdc",
      "85": "#d7d7ea",
      "90": "#ebebf5",
      "00": "#0a0a14",
    },
    Lime: {
      "10": "#263201",
      "20": "#354601",
      "30": "#445a02",
      "40": "#5b7802",
      "45": "#79a003",
      "50": "#97c804",
      "55": "#b5f005",
      "60": "#c5fb23",
      "65": "#cafb37",
      "70": "#cffb4b",
      "75": "#dafc73",
      "80": "#e4fd9b",
      "85": "#effec3",
      "90": "#f7fee1",
      "00": "#171e01",
    },
  },
  Alpha: {
    "Alpha White": {
      "10": "#f9fafb0a",
      "20": "#f9fafb0f",
      "30": "#f9fafb14",
      "40": "#f9fafb1f",
      "45": "#f9fafb29",
      "50": "#f9fafb3d",
      "55": "#f9fafb52",
      "60": "#f9fafb66",
      "65": "#f9fafb70",
      "70": "#f9fafb85",
      "75": "#f9fafbad",
      "80": "#f9fafbc7",
      "85": "#f9fafbe0",
      "90": "#f9fafb",
      "00": "#f9fafb05",
    },
    "Alpha Black": {
      "10": "#0405060a",
      "20": "#0405060f",
      "30": "#04050614",
      "40": "#0405061f",
      "45": "#04050629",
      "50": "#0405063d",
      "55": "#04050652",
      "60": "#04050666",
      "65": "#04050670",
      "70": "#04050685",
      "75": "#040506ad",
      "80": "#040506c7",
      "85": "#040506e0",
      "90": "#040506",
      "00": "#04050605",
    },
    "Utility": { Transparency: "#00000003", White: "#ffffff03" },
  },
  Base: {
    Grey: {
      "10": "#090a0b",
      "20": "#0e0f11",
      "30": "#131416",
      "40": "#1c1f21",
      "45": "#2f3337",
      "50": "#4b5258",
      "55": "#5e666e",
      "60": "#717a84",
      "65": "#868f98",
      "70": "#b2b8bd",
      "75": "#dee0e3",
      "80": "#f4f5f6",
      "85": "#f9fafa",
      "90": "#ffffff",
      "00": "#000000",
    },
    Blue: {
      "10": "#091a2a",
      "20": "#0d243b",
      "30": "#112e4b",
      "40": "#163d64",
      "45": "#1d5286",
      "50": "#2566a7",
      "55": "#2c7ac9",
      "60": "#478fd7",
      "65": "#5899da",
      "70": "#68a3de",
      "75": "#8ab8e5",
      "80": "#abcced",
      "85": "#cde0f4",
      "90": "#e6f0f9",
      "00": "#060f19",
    },
    Green: {
      "10": "#0e2514",
      "20": "#14331c",
      "30": "#1a4224",
      "40": "#225830",
      "45": "#2e7640",
      "50": "#399350",
      "55": "#45b05f",
      "60": "#5dc076",
      "65": "#6cc683",
      "70": "#7bcc8f",
      "75": "#98d7a8",
      "80": "#b6e2c1",
      "85": "#d3eeda",
      "90": "#e9f6ec",
      "00": "#09160c",
    },
    Yellow: {
      "10": "#2e2405",
      "20": "#403207",
      "30": "#534009",
      "40": "#6e560c",
      "45": "#937210",
      "50": "#b88f14",
      "55": "#dcab18",
      "60": "#e9bc35",
      "65": "#ebc247",
      "70": "#edc85a",
      "75": "#f1d47e",
      "80": "#f5e0a3",
      "85": "#f9edc8",
      "90": "#fcf6e3",
      "00": "#1c1503",
    },
    Orange: {
      "10": "#301a03",
      "20": "#432404",
      "30": "#562e06",
      "40": "#733d07",
      "45": "#99520a",
      "50": "#c0660c",
      "55": "#e67a0f",
      "60": "#f28f2c",
      "65": "#f3993f",
      "70": "#f4a352",
      "75": "#f6b879",
      "80": "#f9cc9f",
      "85": "#fbe0c5",
      "90": "#fdf0e2",
      "00": "#1d0f02",
    },
    Red: {
      "10": "#2c0707",
      "20": "#3d0a0a",
      "30": "#4f0d0d",
      "40": "#691111",
      "45": "#8c1717",
      "50": "#af1d1d",
      "55": "#d32222",
      "60": "#e03e3e",
      "65": "#e25050",
      "70": "#e56161",
      "75": "#eb8484",
      "80": "#f1a7a7",
      "85": "#f6caca",
      "90": "#fbe5e5",
      "00": "#1a0404",
    },
  },
} as const;

export const uiDark = {
  colors: {
    contentEmphasis: colorsPrimitive["Base"]["Grey"]["90"],
    contentDefault: colorsPrimitive["Base"]["Grey"]["75"],
    contentSecondary: colorsPrimitive["Base"]["Grey"]["65"],
    contentTertiary: colorsPrimitive["Base"]["Grey"]["55"],
    contentDisabled: colorsPrimitive["Base"]["Grey"]["40"],
    contentPlaceholder: colorsPrimitive["Base"]["Grey"]["45"],
    contentInverted: colorsPrimitive["Base"]["Grey"]["90"],
    contentBad: colorsPrimitive["Base"]["Red"]["55"],
    contentGood: colorsPrimitive["Base"]["Green"]["55"],
    surfaceBackground: colorsPrimitive["Base"]["Grey"]["10"],
    surfaceDefault: colorsPrimitive["Base"]["Grey"]["20"],
    surfaceElevate: colorsPrimitive["Base"]["Grey"]["30"],
    surfaceRaise: colorsPrimitive["Base"]["Grey"]["40"],
    surfaceInverted: colorsPrimitive["Base"]["Grey"]["90"],
    surfaceScrim: colorsPrimitive["Alpha"]["Alpha Black"]["80"],
    surfaceTransparent: colorsPrimitive["Alpha"]["Utility"]["Transparency"],
    strokeDefault: colorsPrimitive["Base"]["Grey"]["40"],
    strokeRaise: colorsPrimitive["Base"]["Grey"]["50"],
    strokeStrong: colorsPrimitive["Base"]["Grey"]["55"],
    strokeEmphasis: colorsPrimitive["Base"]["Grey"]["70"],
    strokeElevate: colorsPrimitive["Base"]["Grey"]["45"],
    statusInformativeContent: colorsPrimitive["Base"]["Blue"]["55"],
    statusInformativeSurface: colorsPrimitive["Base"]["Blue"]["20"],
    statusPositiveContent: colorsPrimitive["Base"]["Green"]["55"],
    statusPositiveSurface: colorsPrimitive["Base"]["Green"]["20"],
    statusWarningContent: colorsPrimitive["Base"]["Orange"]["55"],
    statusWarningSurface: colorsPrimitive["Base"]["Orange"]["20"],
    statusNegativeContent: colorsPrimitive["Base"]["Red"]["55"],
    statusNegativeSurface: colorsPrimitive["Base"]["Red"]["20"],
    statusNeutralContent: colorsPrimitive["Base"]["Grey"]["65"],
    statusNeutralSurface: colorsPrimitive["Base"]["Grey"]["40"],
    accentSurfaceElevate: colorsPrimitive["Accent"]["Navy"]["40"],
    accentSurfaceRaise: colorsPrimitive["Accent"]["Navy"]["45"],
    accentContentDefault: colorsPrimitive["Accent"]["Navy"]["90"],
    accentContentSecondary: colorsPrimitive["Accent"]["Navy"]["70"],
    accentContentTertiary: colorsPrimitive["Accent"]["Navy"]["60"],
    interactivePrimaryDefault: colorsPrimitive["Base"]["Grey"]["85"],
    interactivePrimaryHover: colorsPrimitive["Base"]["Grey"]["70"],
    interactivePrimaryPressed: colorsPrimitive["Base"]["Grey"]["75"],
    interactivePrimaryContent: colorsPrimitive["Base"]["Grey"]["20"],
    interactiveAccentDefault: colorsPrimitive["Base"]["Blue"]["55"],
    interactiveAccentHover: colorsPrimitive["Base"]["Blue"]["45"],
    interactiveAccentPressed: colorsPrimitive["Base"]["Blue"]["50"],
    interactiveAccentContent: colorsPrimitive["Base"]["Grey"]["90"],
    interactiveDefaultDefault: colorsPrimitive["Base"]["Grey"]["40"],
    interactiveDefaultHover: colorsPrimitive["Base"]["Grey"]["45"],
    interactiveDefaultPressed: colorsPrimitive["Base"]["Grey"]["55"],
    interactiveDefaultContent: colorsPrimitive["Base"]["Grey"]["85"],
    interactiveNegativeDefault: colorsPrimitive["Base"]["Red"]["55"],
    interactiveNegativeHover: colorsPrimitive["Base"]["Red"]["45"],
    interactiveNegativePressed: colorsPrimitive["Base"]["Red"]["50"],
    interactiveNegativeContent: colorsPrimitive["Base"]["Grey"]["90"],
    interactiveStatesActive: colorsPrimitive["Base"]["Grey"]["85"],
    interactiveStatesSelected: colorsPrimitive["Base"]["Grey"]["20"],
    interactiveStatesDisabled: colorsPrimitive["Base"]["Grey"]["55"],
    interactiveOverlayDefault: colorsPrimitive["Alpha"]["Alpha White"]["20"],
    interactiveOverlayHover: colorsPrimitive["Alpha"]["Alpha White"]["30"],
    interactiveOverlayPressed: colorsPrimitive["Alpha"]["Alpha White"]["40"],
  },
  shadows: {
    outerFocus: "0px 0px 0px 4px #ffffff, 0px 0px 0px 2px #000000",
    innerFocus: "inset 0px 0px 0px 3px #000000, inset 0px 0px 0px 2px #ffffff",
    macroDown: "inset 0px 0px 4px 0px #0000001a, inset 0px 1px 1px 0px #0000001a",
    macroElevate:
      "0px 0px 8px 0px #000000a3, 0px 2px 2px 0px #000000cc, 0px 1px 2px 0px #000000cc, 0px 1px 1px 0px #000000cc, inset 0px 0px 0px 1px #ffffff0a",
    macroRaise:
      "0px 0px 8px 0px #000000cc, 0px 10px 10px 0px #000000a3, 0px 5px 5px 0px #00000066, 0px 2px 2px 0px #000000a3, 0px 1px 1px 0px #000000a3, inset 0px 0px 0px 1px #ffffff0a",
    macroFloat:
      "0px 0px 8px 0px #00000066, 0px 4px 30px 0px #00000066, 0px 40px 70px 0px #00000066, 0px 30px 40px 0px #00000066, 0px 15px 20px 0px #00000066, 0px 6px 6px 0px #00000066, 0px 2px 4px 0px #00000066, inset 0px 0px 0px 1px #ffffff0a",
    microDown:
      "inset 0px 1px 1px 0px #000000cc, inset 0px 0px 2px 0px #000000cc, 0px 0px 0px 1px #ffffff05",
    microElevate:
      "0px 1px 2px 0px #0000008f, 0px 1px 2px 0px #000000cc, 0px 1px 1px 0px #000000cc, inset 0px 0px 0px 1px #ffffff0a",
    microRaise:
      "0px 1px 4px 0px #000000a3, 0px 2px 4px 0px #000000a3, 0px 2px 2px 0px #000000a3, 0px 1px 1px 0px #000000cc, inset 0px 0px 0px 1px #ffffff0a",
    microFloat:
      "0px 4px 20px 0px #00000066, 0px 4px 6px 0px #0000008f, 0px 3px 5px 0px #0000008f, 0px 2px 4px 0px #0000008f, inset 0px 0px 0px 1px #ffffff0a",
  },
} as const;

export const uiLight = {
  colors: {
    contentEmphasis: colorsPrimitive["Base"]["Grey"]["00"],
    contentDefault: colorsPrimitive["Base"]["Grey"]["30"],
    contentSecondary: colorsPrimitive["Base"]["Grey"]["55"],
    contentTertiary: colorsPrimitive["Base"]["Grey"]["65"],
    contentDisabled: colorsPrimitive["Base"]["Grey"]["75"],
    contentPlaceholder: colorsPrimitive["Base"]["Grey"]["70"],
    contentInverted: colorsPrimitive["Base"]["Grey"]["90"],
    contentBad: colorsPrimitive["Base"]["Red"]["55"],
    contentGood: colorsPrimitive["Base"]["Green"]["55"],
    surfaceBackground: colorsPrimitive["Base"]["Grey"]["80"],
    surfaceDefault: colorsPrimitive["Base"]["Grey"]["90"],
    surfaceElevate: colorsPrimitive["Base"]["Grey"]["85"],
    surfaceRaise: colorsPrimitive["Base"]["Grey"]["75"],
    surfaceInverted: colorsPrimitive["Base"]["Grey"]["30"],
    surfaceScrim: colorsPrimitive["Alpha"]["Alpha Black"]["75"],
    surfaceTransparent: colorsPrimitive["Alpha"]["Utility"]["White"],
    strokeDefault: colorsPrimitive["Base"]["Grey"]["75"],
    strokeRaise: colorsPrimitive["Base"]["Grey"]["65"],
    strokeStrong: colorsPrimitive["Base"]["Grey"]["55"],
    strokeEmphasis: colorsPrimitive["Base"]["Grey"]["30"],
    strokeElevate: colorsPrimitive["Base"]["Grey"]["70"],
    statusInformativeContent: colorsPrimitive["Base"]["Blue"]["55"],
    statusInformativeSurface: colorsPrimitive["Base"]["Blue"]["90"],
    statusPositiveContent: colorsPrimitive["Base"]["Green"]["55"],
    statusPositiveSurface: colorsPrimitive["Base"]["Green"]["90"],
    statusWarningContent: colorsPrimitive["Base"]["Orange"]["55"],
    statusWarningSurface: colorsPrimitive["Base"]["Orange"]["90"],
    statusNegativeContent: colorsPrimitive["Base"]["Red"]["55"],
    statusNegativeSurface: colorsPrimitive["Base"]["Red"]["90"],
    statusNeutralContent: colorsPrimitive["Base"]["Grey"]["55"],
    statusNeutralSurface: colorsPrimitive["Base"]["Grey"]["75"],
    accentSurfaceElevate: colorsPrimitive["Accent"]["Navy"]["85"],
    accentSurfaceRaise: colorsPrimitive["Accent"]["Navy"]["75"],
    accentContentDefault: colorsPrimitive["Accent"]["Navy"]["20"],
    accentContentSecondary: colorsPrimitive["Accent"]["Navy"]["50"],
    accentContentTertiary: colorsPrimitive["Accent"]["Navy"]["60"],
    interactivePrimaryDefault: colorsPrimitive["Base"]["Grey"]["10"],
    interactivePrimaryHover: colorsPrimitive["Base"]["Grey"]["45"],
    interactivePrimaryPressed: colorsPrimitive["Base"]["Grey"]["50"],
    interactivePrimaryContent: colorsPrimitive["Base"]["Grey"]["90"],
    interactiveAccentDefault: "#2c7ac9",
    interactiveAccentHover: "#1d5286",
    interactiveAccentPressed: "#2566a7",
    interactiveAccentContent: colorsPrimitive["Base"]["Grey"]["90"],
    interactiveDefaultDefault: colorsPrimitive["Base"]["Grey"]["75"],
    interactiveDefaultHover: colorsPrimitive["Base"]["Grey"]["70"],
    interactiveDefaultPressed: colorsPrimitive["Base"]["Grey"]["65"],
    interactiveDefaultContent: colorsPrimitive["Base"]["Grey"]["10"],
    interactiveNegativeDefault: "#d32222",
    interactiveNegativeHover: colorsPrimitive["Base"]["Red"]["45"],
    interactiveNegativePressed: "#af1d1d",
    interactiveNegativeContent: colorsPrimitive["Base"]["Grey"]["90"],
    interactiveStatesActive: colorsPrimitive["Base"]["Grey"]["30"],
    interactiveStatesSelected: colorsPrimitive["Base"]["Grey"]["75"],
    interactiveStatesDisabled: colorsPrimitive["Base"]["Grey"]["80"],
    interactiveOverlayDefault: colorsPrimitive["Alpha"]["Alpha Black"]["20"],
    interactiveOverlayHover: colorsPrimitive["Alpha"]["Alpha Black"]["20"],
    interactiveOverlayPressed: colorsPrimitive["Alpha"]["Alpha Black"]["30"],
  },
  shadows: {
    outerFocus: "0px 0px 0px 4px #000000, 0px 0px 0px 2px #ffffff",
    innerFocus: "inset 0px 0px 0px 3px #ffffff, inset 0px 0px 0px 2px #000000",
    macroDown: "inset 0px 0px 4px 0px #0000001a, inset 0px 1px 1px 0px #0000001a",
    macroElevate:
      "0px 0px 8px 0px #0000000f, 0px 2px 2px 0px #00000014, 0px 1px 2px 0px #00000014, 0px 1px 1px 0px #00000014",
    macroRaise:
      "0px 0px 8px 0px #00000014, 0px 10px 10px 0px #0000000f, 0px 5px 5px 0px #0000000a, 0px 2px 2px 0px #0000000f, 0px 1px 1px 0px #0000000f",
    macroFloat:
      "0px 0px 8px 0px #0000000a, 0px 4px 30px 0px #0000000a, 0px 40px 70px 0px #0000000a, 0px 30px 40px 0px #0000000a, 0px 15px 20px 0px #0000000a, 0px 6px 6px 0px #0000000a, 0px 2px 4px 0px #0000000a",
    microDown: "inset 0px 1px 1px 0px #0000001a, inset 0px 0px 2px 0px #0000001a",
    microElevate: "0px 1px 2px 0px #0000000f, 0px 1px 2px 0px #0000001a, 0px 1px 1px 0px #0000001a",
    microRaise:
      "0px 1px 4px 0px #00000014, 0px 2px 4px 0px #00000012, 0px 2px 2px 0px #0000000d, 0px 1px 1px 0px #0000001a",
    microFloat:
      "0px 4px 20px 0px #0000000d, 0px 4px 6px 0px #0000000d, 0px 3px 5px 0px #0000000d, 0px 2px 4px 0px #0000000d",
  },
} as const;

const typeDesktopPrimitives = {
  fontFamilies: { "inter-display": "InterDisplay", "inter": "Inter", "mono": "GT America Mono" },
  lineHeights: {
    "0": "52px",
    "1": "44px",
    "2": "40px",
    "3": "36px",
    "4": "32px",
    "5": "28px",
    "6": "24px",
    "7": "20px",
    "8": "16px",
  },
  fontWeights: { "inter-display-0": 600, "inter-1": 600, "inter-2": 400, "mono-3": 400 },
  fontSize: {
    "0": "10px",
    "1": "11px",
    "2": "12px",
    "3": "13px",
    "4": "16px",
    "5": "18px",
    "6": "20px",
    "7": "24px",
    "8": "28px",
    "9": "32px",
    "10": "36px",
    "11": "44px",
  },
  letterSpacing: { "0": "0%", "1": "-1%", "2": "-2%", "3": "4%", "4": "2%" },
  textCase: { none: "none", uppercase: "uppercase" },
  textDecoration: { none: "none", underline: "underline" },
} as const;

export const typeDesktopSemantics = {
  fontFamily: {
    titleXL: typeDesktopPrimitives.fontFamilies["inter-display"],
    titleLG: typeDesktopPrimitives.fontFamilies["inter-display"],
    titleDefault: typeDesktopPrimitives.fontFamilies["inter-display"],
    titleMD: typeDesktopPrimitives.fontFamilies["inter-display"],
    titleSM: typeDesktopPrimitives.fontFamilies["inter-display"],
    titleXS: typeDesktopPrimitives.fontFamilies["inter"],
    label: typeDesktopPrimitives.fontFamilies["inter"],
    labelStrong: typeDesktopPrimitives.fontFamilies["inter"],
    paragraph: typeDesktopPrimitives.fontFamilies["inter"],
    paragraphStrong: typeDesktopPrimitives.fontFamilies["inter"],
    paragraphLink: typeDesktopPrimitives.fontFamilies["inter"],
    paragraphMono: typeDesktopPrimitives.fontFamilies["mono"],
    body: typeDesktopPrimitives.fontFamilies["inter"],
    bodyStrong: typeDesktopPrimitives.fontFamilies["inter"],
    bodyLink: typeDesktopPrimitives.fontFamilies["inter"],
    bodyMono: typeDesktopPrimitives.fontFamilies["mono"],
    caption: typeDesktopPrimitives.fontFamilies["inter"],
    captionStrong: typeDesktopPrimitives.fontFamilies["inter"],
    captionLink: typeDesktopPrimitives.fontFamilies["inter"],
    captionMono: typeDesktopPrimitives.fontFamilies["mono"],
    hint: typeDesktopPrimitives.fontFamilies["inter"],
    hintStrong: typeDesktopPrimitives.fontFamilies["inter"],
    hintLink: typeDesktopPrimitives.fontFamilies["inter"],
    hintMono: typeDesktopPrimitives.fontFamilies["mono"],
    eyebrow: typeDesktopPrimitives.fontFamilies["inter"],
    eyebrowStrong: typeDesktopPrimitives.fontFamilies["inter"],
  },
  lineHeight: {
    titleXL: typeDesktopPrimitives.lineHeights["0"],
    titleLG: typeDesktopPrimitives.lineHeights["1"],
    titleDefault: typeDesktopPrimitives.lineHeights["2"],
    titleMD: typeDesktopPrimitives.lineHeights["3"],
    titleSM: typeDesktopPrimitives.lineHeights["4"],
    titleXS: typeDesktopPrimitives.lineHeights["5"],
    label: typeDesktopPrimitives.lineHeights["6"],
    labelStrong: typeDesktopPrimitives.lineHeights["6"],
    paragraph: typeDesktopPrimitives.lineHeights["6"],
    paragraphStrong: typeDesktopPrimitives.lineHeights["6"],
    paragraphLink: typeDesktopPrimitives.lineHeights["6"],
    paragraphMono: typeDesktopPrimitives.lineHeights["6"],
    body: typeDesktopPrimitives.lineHeights["7"],
    bodyStrong: typeDesktopPrimitives.lineHeights["7"],
    bodyLink: typeDesktopPrimitives.lineHeights["7"],
    bodyMono: typeDesktopPrimitives.lineHeights["7"],
    caption: typeDesktopPrimitives.lineHeights["8"],
    captionStrong: typeDesktopPrimitives.lineHeights["8"],
    captionLink: typeDesktopPrimitives.lineHeights["8"],
    captionMono: typeDesktopPrimitives.lineHeights["8"],
    hint: typeDesktopPrimitives.lineHeights["8"],
    hintStrong: typeDesktopPrimitives.lineHeights["8"],
    hintLink: typeDesktopPrimitives.lineHeights["8"],
    hintMono: typeDesktopPrimitives.lineHeights["8"],
    eyebrow: typeDesktopPrimitives.lineHeights["8"],
    eyebrowStrong: typeDesktopPrimitives.lineHeights["8"],
  },
  fontWeight: {
    titleXL: typeDesktopPrimitives.fontWeights["inter-display-0"],
    titleLG: typeDesktopPrimitives.fontWeights["inter-display-0"],
    titleDefault: typeDesktopPrimitives.fontWeights["inter-display-0"],
    titleMD: typeDesktopPrimitives.fontWeights["inter-display-0"],
    titleSM: typeDesktopPrimitives.fontWeights["inter-display-0"],
    titleXS: typeDesktopPrimitives.fontWeights["inter-display-0"],
    label: typeDesktopPrimitives.fontWeights["inter-2"],
    labelStrong: typeDesktopPrimitives.fontWeights["inter-display-0"],
    paragraph: typeDesktopPrimitives.fontWeights["inter-2"],
    paragraphStrong: typeDesktopPrimitives.fontWeights["inter-display-0"],
    paragraphLink: typeDesktopPrimitives.fontWeights["inter-2"],
    paragraphMono: typeDesktopPrimitives.fontWeights["mono-3"],
    body: typeDesktopPrimitives.fontWeights["inter-2"],
    bodyStrong: typeDesktopPrimitives.fontWeights["inter-display-0"],
    bodyLink: typeDesktopPrimitives.fontWeights["inter-2"],
    bodyMono: typeDesktopPrimitives.fontWeights["mono-3"],
    caption: typeDesktopPrimitives.fontWeights["inter-2"],
    captionStrong: typeDesktopPrimitives.fontWeights["inter-display-0"],
    captionLink: typeDesktopPrimitives.fontWeights["inter-2"],
    captionMono: typeDesktopPrimitives.fontWeights["mono-3"],
    hint: typeDesktopPrimitives.fontWeights["inter-2"],
    hintStrong: typeDesktopPrimitives.fontWeights["inter-display-0"],
    hintLink: typeDesktopPrimitives.fontWeights["inter-2"],
    hintMono: typeDesktopPrimitives.fontWeights["mono-3"],
    eyebrow: typeDesktopPrimitives.fontWeights["inter-2"],
    eyebrowStrong: typeDesktopPrimitives.fontWeights["inter-display-0"],
  },
  fontSize: {
    titleXL: typeDesktopPrimitives.fontSize["11"],
    titleLG: typeDesktopPrimitives.fontSize["10"],
    titleDefault: typeDesktopPrimitives.fontSize["9"],
    titleMD: typeDesktopPrimitives.fontSize["8"],
    titleSM: typeDesktopPrimitives.fontSize["7"],
    titleXS: typeDesktopPrimitives.fontSize["6"],
    label: typeDesktopPrimitives.fontSize["5"],
    labelStrong: typeDesktopPrimitives.fontSize["5"],
    paragraph: typeDesktopPrimitives.fontSize["4"],
    paragraphStrong: typeDesktopPrimitives.fontSize["4"],
    paragraphLink: typeDesktopPrimitives.fontSize["4"],
    paragraphMono: typeDesktopPrimitives.fontSize["4"],
    body: typeDesktopPrimitives.fontSize["3"],
    bodyStrong: typeDesktopPrimitives.fontSize["3"],
    bodyLink: typeDesktopPrimitives.fontSize["3"],
    bodyMono: typeDesktopPrimitives.fontSize["3"],
    caption: typeDesktopPrimitives.fontSize["2"],
    captionStrong: typeDesktopPrimitives.fontSize["2"],
    captionLink: typeDesktopPrimitives.fontSize["2"],
    captionMono: typeDesktopPrimitives.fontSize["2"],
    hint: typeDesktopPrimitives.fontSize["1"],
    hintStrong: typeDesktopPrimitives.fontSize["1"],
    hintLink: typeDesktopPrimitives.fontSize["1"],
    hintMono: typeDesktopPrimitives.fontSize["1"],
    eyebrow: typeDesktopPrimitives.fontSize["0"],
    eyebrowStrong: typeDesktopPrimitives.fontSize["0"],
  },
  letterSpacing: {
    titleXL: typeDesktopPrimitives.letterSpacing["0"],
    titleLG: typeDesktopPrimitives.letterSpacing["0"],
    titleDefault: typeDesktopPrimitives.letterSpacing["0"],
    titleMD: typeDesktopPrimitives.letterSpacing["0"],
    titleSM: typeDesktopPrimitives.letterSpacing["0"],
    titleXS: typeDesktopPrimitives.letterSpacing["1"],
    label: typeDesktopPrimitives.letterSpacing["2"],
    labelStrong: typeDesktopPrimitives.letterSpacing["2"],
    paragraph: typeDesktopPrimitives.letterSpacing["2"],
    paragraphStrong: typeDesktopPrimitives.letterSpacing["2"],
    paragraphLink: typeDesktopPrimitives.letterSpacing["2"],
    paragraphMono: typeDesktopPrimitives.letterSpacing["3"],
    body: typeDesktopPrimitives.letterSpacing["2"],
    bodyStrong: typeDesktopPrimitives.letterSpacing["2"],
    bodyLink: typeDesktopPrimitives.letterSpacing["2"],
    bodyMono: typeDesktopPrimitives.letterSpacing["3"],
    caption: typeDesktopPrimitives.letterSpacing["1"],
    captionStrong: typeDesktopPrimitives.letterSpacing["1"],
    captionLink: typeDesktopPrimitives.letterSpacing["1"],
    captionMono: typeDesktopPrimitives.letterSpacing["3"],
    hint: typeDesktopPrimitives.letterSpacing["1"],
    hintStrong: typeDesktopPrimitives.letterSpacing["1"],
    hintLink: typeDesktopPrimitives.letterSpacing["1"],
    hintMono: typeDesktopPrimitives.letterSpacing["3"],
    eyebrow: typeDesktopPrimitives.letterSpacing["4"],
    eyebrowStrong: typeDesktopPrimitives.letterSpacing["4"],
  },
  textCase: {
    titleXL: typeDesktopPrimitives.textCase["none"],
    titleLG: typeDesktopPrimitives.textCase["none"],
    titleDefault: typeDesktopPrimitives.textCase["none"],
    titleMD: typeDesktopPrimitives.textCase["none"],
    titleSM: typeDesktopPrimitives.textCase["none"],
    titleXS: typeDesktopPrimitives.textCase["none"],
    label: typeDesktopPrimitives.textCase["none"],
    labelStrong: typeDesktopPrimitives.textCase["none"],
    paragraph: typeDesktopPrimitives.textCase["none"],
    paragraphStrong: typeDesktopPrimitives.textCase["none"],
    paragraphLink: typeDesktopPrimitives.textCase["none"],
    paragraphMono: typeDesktopPrimitives.textCase["uppercase"],
    body: typeDesktopPrimitives.textCase["none"],
    bodyStrong: typeDesktopPrimitives.textCase["none"],
    bodyLink: typeDesktopPrimitives.textCase["none"],
    bodyMono: typeDesktopPrimitives.textCase["uppercase"],
    caption: typeDesktopPrimitives.textCase["none"],
    captionStrong: typeDesktopPrimitives.textCase["none"],
    captionLink: typeDesktopPrimitives.textCase["none"],
    captionMono: typeDesktopPrimitives.textCase["uppercase"],
    hint: typeDesktopPrimitives.textCase["none"],
    hintStrong: typeDesktopPrimitives.textCase["none"],
    hintLink: typeDesktopPrimitives.textCase["none"],
    hintMono: typeDesktopPrimitives.textCase["uppercase"],
    eyebrow: typeDesktopPrimitives.textCase["uppercase"],
    eyebrowStrong: typeDesktopPrimitives.textCase["uppercase"],
  },
  textDecoration: {
    titleXL: typeDesktopPrimitives.textDecoration["none"],
    titleLG: typeDesktopPrimitives.textDecoration["none"],
    titleDefault: typeDesktopPrimitives.textDecoration["none"],
    titleMD: typeDesktopPrimitives.textDecoration["none"],
    titleSM: typeDesktopPrimitives.textDecoration["none"],
    titleXS: typeDesktopPrimitives.textDecoration["none"],
    label: typeDesktopPrimitives.textDecoration["none"],
    labelStrong: typeDesktopPrimitives.textDecoration["none"],
    paragraph: typeDesktopPrimitives.textDecoration["none"],
    paragraphStrong: typeDesktopPrimitives.textDecoration["none"],
    paragraphLink: typeDesktopPrimitives.textDecoration["underline"],
    paragraphMono: typeDesktopPrimitives.textDecoration["none"],
    body: typeDesktopPrimitives.textDecoration["none"],
    bodyStrong: typeDesktopPrimitives.textDecoration["none"],
    bodyLink: typeDesktopPrimitives.textDecoration["underline"],
    bodyMono: typeDesktopPrimitives.textDecoration["none"],
    caption: typeDesktopPrimitives.textDecoration["none"],
    captionStrong: typeDesktopPrimitives.textDecoration["none"],
    captionLink: typeDesktopPrimitives.textDecoration["underline"],
    captionMono: typeDesktopPrimitives.textDecoration["none"],
    hint: typeDesktopPrimitives.textDecoration["none"],
    hintStrong: typeDesktopPrimitives.textDecoration["none"],
    hintLink: typeDesktopPrimitives.textDecoration["underline"],
    hintMono: typeDesktopPrimitives.textDecoration["none"],
    eyebrow: typeDesktopPrimitives.textDecoration["none"],
    eyebrowStrong: typeDesktopPrimitives.textDecoration["none"],
  },
} as const;

const typeMobilePrimitives = {
  fontFamilies: { "inter-display": "InterDisplay", "inter": "Inter", "mono": "GT America Mono" },
  lineHeights: {
    "0": "44px",
    "1": "40px",
    "2": "36px",
    "3": "32px",
    "4": "28px",
    "5": "24px",
    "6": "20px",
    "7": "16px",
  },
  fontWeights: { "inter-display-0": 600, "inter-1": 600, "inter-2": 400, "mono-3": 400 },
  fontSize: {
    "0": "10px",
    "1": "11px",
    "2": "12px",
    "3": "13px",
    "4": "16px",
    "5": "18px",
    "6": "20px",
    "7": "24px",
    "8": "28px",
    "9": "32px",
    "10": "40px",
  },
  letterSpacing: { "0": "0%", "1": "-1%", "2": "-2%", "3": "4%", "4": "2%" },
  textCase: { none: "none", uppercase: "uppercase" },
  textDecoration: { none: "none", underline: "underline" },
} as const;

export const typeMobileSemantics = {
  fontFamily: {
    titleXL: typeMobilePrimitives.fontFamilies["inter-display"],
    titleLG: typeMobilePrimitives.fontFamilies["inter-display"],
    titleDefault: typeMobilePrimitives.fontFamilies["inter-display"],
    titleMD: typeMobilePrimitives.fontFamilies["inter-display"],
    titleSM: typeMobilePrimitives.fontFamilies["inter-display"],
    titleXS: typeMobilePrimitives.fontFamilies["inter"],
    label: typeMobilePrimitives.fontFamilies["inter"],
    labelStrong: typeMobilePrimitives.fontFamilies["inter"],
    paragraph: typeMobilePrimitives.fontFamilies["inter"],
    paragraphStrong: typeMobilePrimitives.fontFamilies["inter"],
    paragraphLink: typeMobilePrimitives.fontFamilies["inter"],
    paragraphMono: typeMobilePrimitives.fontFamilies["mono"],
    body: typeMobilePrimitives.fontFamilies["inter"],
    bodyStrong: typeMobilePrimitives.fontFamilies["inter"],
    bodyLink: typeMobilePrimitives.fontFamilies["inter"],
    bodyMono: typeMobilePrimitives.fontFamilies["mono"],
    caption: typeMobilePrimitives.fontFamilies["inter"],
    captionStrong: typeMobilePrimitives.fontFamilies["inter"],
    captionLink: typeMobilePrimitives.fontFamilies["inter"],
    captionMono: typeMobilePrimitives.fontFamilies["mono"],
    hint: typeMobilePrimitives.fontFamilies["inter"],
    hintStrong: typeMobilePrimitives.fontFamilies["inter"],
    hintLink: typeMobilePrimitives.fontFamilies["inter"],
    hintMono: typeMobilePrimitives.fontFamilies["mono"],
    eyebrow: typeMobilePrimitives.fontFamilies["inter"],
    eyebrowStrong: typeMobilePrimitives.fontFamilies["inter"],
  },
  lineHeight: {
    titleXL: typeMobilePrimitives.lineHeights["0"],
    titleLG: typeMobilePrimitives.lineHeights["1"],
    titleDefault: typeMobilePrimitives.lineHeights["2"],
    titleMD: typeMobilePrimitives.lineHeights["3"],
    titleSM: typeMobilePrimitives.lineHeights["4"],
    titleXS: typeMobilePrimitives.lineHeights["5"],
    label: typeMobilePrimitives.lineHeights["5"],
    labelStrong: typeMobilePrimitives.lineHeights["5"],
    paragraph: typeMobilePrimitives.lineHeights["5"],
    paragraphStrong: typeMobilePrimitives.lineHeights["5"],
    paragraphLink: typeMobilePrimitives.lineHeights["5"],
    paragraphMono: typeMobilePrimitives.lineHeights["5"],
    body: typeMobilePrimitives.lineHeights["6"],
    bodyStrong: typeMobilePrimitives.lineHeights["6"],
    bodyLink: typeMobilePrimitives.lineHeights["6"],
    bodyMono: typeMobilePrimitives.lineHeights["6"],
    caption: typeMobilePrimitives.lineHeights["7"],
    captionStrong: typeMobilePrimitives.lineHeights["7"],
    captionLink: typeMobilePrimitives.lineHeights["7"],
    captionMono: typeMobilePrimitives.lineHeights["7"],
    hint: typeMobilePrimitives.lineHeights["7"],
    hintStrong: typeMobilePrimitives.lineHeights["7"],
    hintLink: typeMobilePrimitives.lineHeights["7"],
    hintMono: typeMobilePrimitives.lineHeights["7"],
    eyebrow: typeMobilePrimitives.lineHeights["7"],
    eyebrowStrong: typeMobilePrimitives.lineHeights["7"],
  },
  fontWeight: {
    titleXL: typeMobilePrimitives.fontWeights["inter-display-0"],
    titleLG: typeMobilePrimitives.fontWeights["inter-display-0"],
    titleDefault: typeMobilePrimitives.fontWeights["inter-display-0"],
    titleMD: typeMobilePrimitives.fontWeights["inter-display-0"],
    titleSM: typeMobilePrimitives.fontWeights["inter-display-0"],
    titleXS: typeMobilePrimitives.fontWeights["inter-display-0"],
    label: typeMobilePrimitives.fontWeights["inter-2"],
    labelStrong: typeMobilePrimitives.fontWeights["inter-display-0"],
    paragraph: typeMobilePrimitives.fontWeights["inter-2"],
    paragraphStrong: typeMobilePrimitives.fontWeights["inter-display-0"],
    paragraphLink: typeMobilePrimitives.fontWeights["inter-2"],
    paragraphMono: typeMobilePrimitives.fontWeights["mono-3"],
    body: typeMobilePrimitives.fontWeights["inter-2"],
    bodyStrong: typeMobilePrimitives.fontWeights["inter-display-0"],
    bodyLink: typeMobilePrimitives.fontWeights["inter-2"],
    bodyMono: typeMobilePrimitives.fontWeights["mono-3"],
    caption: typeMobilePrimitives.fontWeights["inter-2"],
    captionStrong: typeMobilePrimitives.fontWeights["inter-display-0"],
    captionLink: typeMobilePrimitives.fontWeights["inter-2"],
    captionMono: typeMobilePrimitives.fontWeights["mono-3"],
    hint: typeMobilePrimitives.fontWeights["inter-2"],
    hintStrong: typeMobilePrimitives.fontWeights["inter-display-0"],
    hintLink: typeMobilePrimitives.fontWeights["inter-2"],
    hintMono: typeMobilePrimitives.fontWeights["mono-3"],
    eyebrow: typeMobilePrimitives.fontWeights["inter-2"],
    eyebrowStrong: typeMobilePrimitives.fontWeights["inter-display-0"],
  },
  fontSize: {
    titleXL: typeMobilePrimitives.fontSize["10"],
    titleLG: typeMobilePrimitives.fontSize["9"],
    titleDefault: typeMobilePrimitives.fontSize["8"],
    titleMD: typeMobilePrimitives.fontSize["7"],
    titleSM: typeMobilePrimitives.fontSize["6"],
    titleXS: typeMobilePrimitives.fontSize["5"],
    label: typeMobilePrimitives.fontSize["5"],
    labelStrong: typeMobilePrimitives.fontSize["5"],
    paragraph: typeMobilePrimitives.fontSize["4"],
    paragraphStrong: typeMobilePrimitives.fontSize["4"],
    paragraphLink: typeMobilePrimitives.fontSize["4"],
    paragraphMono: typeMobilePrimitives.fontSize["4"],
    body: typeMobilePrimitives.fontSize["3"],
    bodyStrong: typeMobilePrimitives.fontSize["3"],
    bodyLink: typeMobilePrimitives.fontSize["3"],
    bodyMono: typeMobilePrimitives.fontSize["3"],
    caption: typeMobilePrimitives.fontSize["2"],
    captionStrong: typeMobilePrimitives.fontSize["2"],
    captionLink: typeMobilePrimitives.fontSize["2"],
    captionMono: typeMobilePrimitives.fontSize["2"],
    hint: typeMobilePrimitives.fontSize["1"],
    hintStrong: typeMobilePrimitives.fontSize["1"],
    hintLink: typeMobilePrimitives.fontSize["1"],
    hintMono: typeMobilePrimitives.fontSize["1"],
    eyebrow: typeMobilePrimitives.fontSize["0"],
    eyebrowStrong: typeMobilePrimitives.fontSize["0"],
  },
  letterSpacing: {
    titleXL: typeMobilePrimitives.letterSpacing["0"],
    titleLG: typeMobilePrimitives.letterSpacing["0"],
    titleDefault: typeMobilePrimitives.letterSpacing["0"],
    titleMD: typeMobilePrimitives.letterSpacing["0"],
    titleSM: typeMobilePrimitives.letterSpacing["0"],
    titleXS: typeMobilePrimitives.letterSpacing["1"],
    label: typeMobilePrimitives.letterSpacing["2"],
    labelStrong: typeMobilePrimitives.letterSpacing["2"],
    paragraph: typeMobilePrimitives.letterSpacing["2"],
    paragraphStrong: typeMobilePrimitives.letterSpacing["2"],
    paragraphLink: typeMobilePrimitives.letterSpacing["2"],
    paragraphMono: typeMobilePrimitives.letterSpacing["3"],
    body: typeMobilePrimitives.letterSpacing["2"],
    bodyStrong: typeMobilePrimitives.letterSpacing["2"],
    bodyLink: typeMobilePrimitives.letterSpacing["2"],
    bodyMono: typeMobilePrimitives.letterSpacing["3"],
    caption: typeMobilePrimitives.letterSpacing["1"],
    captionStrong: typeMobilePrimitives.letterSpacing["1"],
    captionLink: typeMobilePrimitives.letterSpacing["1"],
    captionMono: typeMobilePrimitives.letterSpacing["3"],
    hint: typeMobilePrimitives.letterSpacing["1"],
    hintStrong: typeMobilePrimitives.letterSpacing["1"],
    hintLink: typeMobilePrimitives.letterSpacing["1"],
    hintMono: typeMobilePrimitives.letterSpacing["3"],
    eyebrow: typeMobilePrimitives.letterSpacing["4"],
    eyebrowStrong: typeMobilePrimitives.letterSpacing["4"],
  },
  textCase: {
    titleXL: typeMobilePrimitives.textCase["none"],
    titleLG: typeMobilePrimitives.textCase["none"],
    titleDefault: typeMobilePrimitives.textCase["none"],
    titleMD: typeMobilePrimitives.textCase["none"],
    titleSM: typeMobilePrimitives.textCase["none"],
    titleXS: typeMobilePrimitives.textCase["none"],
    label: typeMobilePrimitives.textCase["none"],
    labelStrong: typeMobilePrimitives.textCase["none"],
    paragraph: typeMobilePrimitives.textCase["none"],
    paragraphStrong: typeMobilePrimitives.textCase["none"],
    paragraphLink: typeMobilePrimitives.textCase["none"],
    paragraphMono: typeMobilePrimitives.textCase["uppercase"],
    body: typeMobilePrimitives.textCase["none"],
    bodyStrong: typeMobilePrimitives.textCase["none"],
    bodyLink: typeMobilePrimitives.textCase["none"],
    bodyMono: typeMobilePrimitives.textCase["uppercase"],
    caption: typeMobilePrimitives.textCase["none"],
    captionStrong: typeMobilePrimitives.textCase["none"],
    captionLink: typeMobilePrimitives.textCase["none"],
    captionMono: typeMobilePrimitives.textCase["uppercase"],
    hint: typeMobilePrimitives.textCase["none"],
    hintStrong: typeMobilePrimitives.textCase["none"],
    hintLink: typeMobilePrimitives.textCase["none"],
    hintMono: typeMobilePrimitives.textCase["uppercase"],
    eyebrow: typeMobilePrimitives.textCase["uppercase"],
    eyebrowStrong: typeMobilePrimitives.textCase["uppercase"],
  },
  textDecoration: {
    titleXL: typeMobilePrimitives.textDecoration["none"],
    titleLG: typeMobilePrimitives.textDecoration["none"],
    titleDefault: typeMobilePrimitives.textDecoration["none"],
    titleMD: typeMobilePrimitives.textDecoration["none"],
    titleSM: typeMobilePrimitives.textDecoration["none"],
    titleXS: typeMobilePrimitives.textDecoration["none"],
    label: typeMobilePrimitives.textDecoration["none"],
    labelStrong: typeMobilePrimitives.textDecoration["none"],
    paragraph: typeMobilePrimitives.textDecoration["none"],
    paragraphStrong: typeMobilePrimitives.textDecoration["none"],
    paragraphLink: typeMobilePrimitives.textDecoration["underline"],
    paragraphMono: typeMobilePrimitives.textDecoration["none"],
    body: typeMobilePrimitives.textDecoration["none"],
    bodyStrong: typeMobilePrimitives.textDecoration["none"],
    bodyLink: typeMobilePrimitives.textDecoration["underline"],
    bodyMono: typeMobilePrimitives.textDecoration["none"],
    caption: typeMobilePrimitives.textDecoration["none"],
    captionStrong: typeMobilePrimitives.textDecoration["none"],
    captionLink: typeMobilePrimitives.textDecoration["underline"],
    captionMono: typeMobilePrimitives.textDecoration["none"],
    hint: typeMobilePrimitives.textDecoration["none"],
    hintStrong: typeMobilePrimitives.textDecoration["none"],
    hintLink: typeMobilePrimitives.textDecoration["underline"],
    hintMono: typeMobilePrimitives.textDecoration["none"],
    eyebrow: typeMobilePrimitives.textDecoration["none"],
    eyebrowStrong: typeMobilePrimitives.textDecoration["none"],
  },
} as const;

export const typeVariants = [
  "titleXL",
  "titleLG",
  "titleDefault",
  "titleMD",
  "titleSM",
  "titleXS",
  "label",
  "labelStrong",
  "paragraph",
  "paragraphStrong",
  "paragraphLink",
  "paragraphMono",
  "body",
  "bodyStrong",
  "bodyLink",
  "bodyMono",
  "caption",
  "captionStrong",
  "captionLink",
  "captionMono",
  "hint",
  "hintStrong",
  "hintLink",
  "hintMono",
  "eyebrow",
  "eyebrowStrong",
] as const;
