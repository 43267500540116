/**
 * We define common groups of variants in this file that Design System components
 * consume.
 */
import { css, t } from ".";

const sizeVariants = css({
  variants: {
    width: {
      "auto": {
        width: "auto",
      },
      "full": {
        width: "100%",
      },
      "100vw": {
        width: "100vw",
      },
      "min-content": {
        width: "min-content",
      },
      "max-content": {
        width: "max-content",
      },
      "fit-content": {
        width: "fit-content",
      },
      "25pc": {
        width: "25%",
      },
      "50pc": {
        width: "50%",
      },
      "75pc": {
        width: "75%",
      },
      "20pc": {
        width: "20%",
      },
      "40pc": {
        width: "40%",
      },
      "60pc": {
        width: "60%",
      },
      "80pc": {
        width: "80%",
      },
      "0": {
        width: t.sizes[0],
      },
      "0.5": {
        width: t.sizes[0.5],
      },
      "1": {
        width: t.sizes[1],
      },
      "2": {
        width: t.sizes[2],
      },
      "3": {
        width: t.sizes[3],
      },
      "4": {
        width: t.sizes[4],
      },
      "5": {
        width: t.sizes[5],
      },
      "6": {
        width: t.sizes[6],
      },
      "7": {
        width: t.sizes[7],
      },
      "8": {
        width: t.sizes[8],
      },
      "9": {
        width: t.sizes[9],
      },
      "10": {
        width: t.sizes[10],
      },
      "12": {
        width: t.sizes[12],
      },
      "14": {
        width: t.sizes[14],
      },
      "16": {
        width: t.sizes[16],
      },
      "18": {
        width: t.sizes[18],
      },
      "20": {
        width: t.sizes[20],
      },
      "24": {
        width: t.sizes[24],
      },
      "28": {
        width: t.sizes[28],
      },
      "32": {
        width: t.sizes[32],
      },
      "36": {
        width: t.sizes[36],
      },
      "40": {
        width: t.sizes[40],
      },
      "44": {
        width: t.sizes[44],
      },
      "48": {
        width: t.sizes[48],
      },
      "52": {
        width: t.sizes[52],
      },
      "56": {
        width: t.sizes[56],
      },
      "60": {
        width: t.sizes[60],
      },
      "64": {
        width: t.sizes[64],
      },
      "72": {
        width: t.sizes[72],
      },
      "80": {
        width: t.sizes[80],
      },
      "96": {
        width: t.sizes[96],
      },
      "104": {
        width: t.sizes[104],
      },
      "112": {
        width: t.sizes[112],
      },
      "120": {
        width: t.sizes[120],
      },
      "136": {
        width: t.sizes[136],
      },
      "152": {
        width: t.sizes[152],
      },
    },
    minWidth: {
      "min-content": {
        minWidth: "min-content",
      },
      "max-content": {
        minWidth: "max-content",
      },
      "fit-content": {
        minWidth: "fit-content",
      },
      "0": {
        minWidth: "0",
      },
      "full": {
        minWidth: "100%",
      },
    },
    maxWidth: {
      "min-content": {
        maxWidth: "min-content",
      },
      "max-content": {
        maxWidth: "max-content",
      },
      "fit-content": {
        maxWidth: "fit-content",
      },
      "none": {
        maxWidth: "none",
      },
      "full": {
        maxWidth: "100%",
      },
      "0": {
        maxWidth: t.sizes[0],
      },
      "8": {
        maxWidth: t.sizes[8],
      },
      "16": {
        maxWidth: t.sizes[16],
      },
      "20": {
        maxWidth: t.sizes[20],
      },
      "32": {
        maxWidth: t.sizes[32],
      },
      "48": {
        maxWidth: t.sizes[48],
      },
      "64": {
        maxWidth: t.sizes[64],
      },
      "96": {
        maxWidth: t.sizes[96],
      },
      "104": {
        maxWidth: t.sizes[104],
      },
      "112": {
        maxWidth: t.sizes[112],
      },
      "120": {
        maxWidth: t.sizes[120],
      },
      "136": {
        maxWidth: t.sizes[136],
      },
      "152": {
        maxWidth: t.sizes[152],
      },
    },
    height: {
      "auto": {
        height: "auto",
      },
      "full": {
        height: "100%",
      },
      "100vh": {
        height: "100vh",
      },
      "min-content": {
        height: "min-content",
      },
      "max-content": {
        height: "max-content",
      },
      "fit-content": {
        height: "fit-content",
      },
      "25pc": {
        height: "25%",
      },
      "50pc": {
        height: "50%",
      },
      "75pc": {
        height: "75%",
      },
      "20pc": {
        height: "20%",
      },
      "40pc": {
        height: "40%",
      },
      "60pc": {
        height: "60%",
      },
      "80pc": {
        height: "80%",
      },
      "0": {
        height: t.sizes[0],
      },
      "0.5": {
        height: t.sizes[0.5],
      },
      "1": {
        height: t.sizes[1],
      },
      "2": {
        height: t.sizes[2],
      },
      "3": {
        height: t.sizes[3],
      },
      "4": {
        height: t.sizes[4],
      },
      "5": {
        height: t.sizes[5],
      },
      "6": {
        height: t.sizes[6],
      },
      "7": {
        height: t.sizes[7],
      },
      "8": {
        height: t.sizes[8],
      },
      "9": {
        height: t.sizes[9],
      },
      "10": {
        height: t.sizes[10],
      },
      "12": {
        height: t.sizes[12],
      },
      "14": {
        height: t.sizes[14],
      },
      "16": {
        height: t.sizes[16],
      },
      "18": {
        height: t.sizes[18],
      },
      "20": {
        height: t.sizes[20],
      },
      "24": {
        height: t.sizes[24],
      },
      "28": {
        height: t.sizes[28],
      },
      "32": {
        height: t.sizes[32],
      },
      "36": {
        height: t.sizes[36],
      },
      "40": {
        height: t.sizes[40],
      },
      "44": {
        height: t.sizes[44],
      },
      "48": {
        height: t.sizes[48],
      },
      "52": {
        height: t.sizes[52],
      },
      "56": {
        height: t.sizes[56],
      },
      "60": {
        height: t.sizes[60],
      },
      "64": {
        height: t.sizes[64],
      },
      "72": {
        height: t.sizes[72],
      },
      "80": {
        height: t.sizes[80],
      },
      "96": {
        height: t.sizes[96],
      },
    },
    minHeight: {
      "min-content": {
        minHeight: "min-content",
      },
      "max-content": {
        minHeight: "max-content",
      },
      "fit-content": {
        minHeight: "fit-content",
      },
      "100%": {
        minHeight: "100%",
      },
      "100vh": {
        minHeight: "100vh",
      },
      "0": {
        minHeight: t.sizes[0],
      },
    },
    maxHeight: {
      "100%": {
        maxHeight: "100%",
      },
      "100vh": {
        maxHeight: "100vh",
      },
      "min-content": {
        maxHeight: "min-content",
      },
      "max-content": {
        maxHeight: "max-content",
      },
      "fit-content": {
        maxHeight: "fit-content",
      },
      "0": {
        maxHeight: t.sizes[0],
      },
      "0.5": {
        maxHeight: t.sizes[0.5],
      },
      "1": {
        maxHeight: t.sizes[1],
      },
      "2": {
        maxHeight: t.sizes[2],
      },
      "3": {
        maxHeight: t.sizes[3],
      },
      "4": {
        maxHeight: t.sizes[4],
      },
      "5": {
        maxHeight: t.sizes[5],
      },
      "6": {
        maxHeight: t.sizes[6],
      },
      "7": {
        maxHeight: t.sizes[7],
      },
      "8": {
        maxHeight: t.sizes[8],
      },
      "9": {
        maxHeight: t.sizes[9],
      },
      "10": {
        maxHeight: t.sizes[10],
      },
      "12": {
        maxHeight: t.sizes[12],
      },
      "14": {
        maxHeight: t.sizes[14],
      },
      "16": {
        maxHeight: t.sizes[16],
      },
      "18": {
        maxHeight: t.sizes[18],
      },
      "20": {
        maxHeight: t.sizes[20],
      },
      "24": {
        maxHeight: t.sizes[24],
      },
      "28": {
        maxHeight: t.sizes[28],
      },
      "32": {
        maxHeight: t.sizes[32],
      },
      "36": {
        maxHeight: t.sizes[36],
      },
      "40": {
        maxHeight: t.sizes[40],
      },
      "44": {
        maxHeight: t.sizes[44],
      },
      "48": {
        maxHeight: t.sizes[48],
      },
      "52": {
        maxHeight: t.sizes[52],
      },
      "56": {
        maxHeight: t.sizes[56],
      },
      "60": {
        maxHeight: t.sizes[60],
      },
      "64": {
        maxHeight: t.sizes[64],
      },
      "72": {
        maxHeight: t.sizes[72],
      },
      "80": {
        maxHeight: t.sizes[80],
      },
      "96": {
        maxHeight: t.sizes[96],
      },
    },
  },
});

const sizeStorybookArgs = {
  width: {
    description: "These are the size tokens, but also percentages and width-specific values.",
    control: { type: "select" },
    table: {
      category: "Size Variants",
      defaultValue: { summary: "auto" },
    },
  },
  maxWidth: {
    description: "These are selected size tokens with some maxWidth-specific values.",
    control: { type: "select" },
    table: {
      category: "Size Variants",
    },
  },
  minWidth: {
    control: { type: "select" },
    table: {
      category: "Size Variants",
    },
  },
  height: {
    description: "These are the size tokens, but also percentages and height-specific values.",
    control: { type: "select" },
    table: {
      category: "Size Variants",
      defaultValue: { summary: "auto" },
    },
  },
  maxHeight: {
    description: "These are selected size tokens with some maxHeight-specific values.",
    control: { type: "select" },
    table: {
      category: "Size Variants",
    },
  },
  minHeight: {
    control: { type: "select" },
    table: {
      category: "Size Variants",
    },
  },
};

const gapVariants = css({
  variants: {
    gap: {
      "0": {
        gap: t.space[0],
      },
      "0.5": {
        gap: t.space[0.5],
      },
      "1": {
        gap: t.space[1],
      },
      "2": {
        gap: t.space[2],
      },
      "3": {
        gap: t.space[3],
      },
      "4": {
        gap: t.space[4],
      },
      "5": {
        gap: t.space[5],
      },
      "6": {
        gap: t.space[6],
      },
      "8": {
        gap: t.space[8],
      },
      "10": {
        gap: t.space[10],
      },
      "16": {
        gap: t.space[16],
      },
      "20": {
        gap: t.space[20],
      },
    },
    rowGap: {
      "0": {
        rowGap: t.space[0],
      },
      "0.5": {
        rowGap: t.space[0.5],
      },
      "1": {
        rowGap: t.space[1],
      },
      "2": {
        rowGap: t.space[2],
      },
      "3": {
        rowGap: t.space[3],
      },
      "4": {
        rowGap: t.space[4],
      },
      "5": {
        rowGap: t.space[5],
      },
      "6": {
        rowGap: t.space[6],
      },
      "8": {
        rowGap: t.space[8],
      },
      "10": {
        rowGap: t.space[10],
      },
      "16": {
        rowGap: t.space[16],
      },
      "20": {
        rowGap: t.space[20],
      },
    },
    columnGap: {
      "0": {
        columnGap: t.space[0],
      },
      "0.5": {
        columnGap: t.space[0.5],
      },
      "1": {
        columnGap: t.space[1],
      },
      "2": {
        columnGap: t.space[2],
      },
      "3": {
        columnGap: t.space[3],
      },
      "4": {
        columnGap: t.space[4],
      },
      "5": {
        columnGap: t.space[5],
      },
      "6": {
        columnGap: t.space[6],
      },
      "8": {
        columnGap: t.space[8],
      },
      "10": {
        columnGap: t.space[10],
      },
      "16": {
        columnGap: t.space[16],
      },
      "20": {
        columnGap: t.space[20],
      },
    },
  },
});

const gapStorybookArgs = {
  gap: {
    description: "The space between elements, on both row and column axes.",
    control: { type: "select" },
    table: {
      category: "Gap Variants",
    },
  },
  rowGap: {
    description: "The space between elements on the row axis.",
    control: { type: "select" },
    table: {
      category: "Gap Variants",
    },
  },
  columnGap: {
    description: "The space between elements on the column axis.",
    control: { type: "select" },
    table: {
      category: "Gap Variants",
    },
  },
};

const marginVariants = css({
  variants: {
    m: {
      "0": {
        margin: t.space[0],
      },
      "0.5": {
        margin: t.space[0.5],
      },
      "1": {
        margin: t.space[1],
      },
      "2": {
        margin: t.space[2],
      },
      "3": {
        margin: t.space[3],
      },
      "4": {
        margin: t.space[4],
      },
      "5": {
        margin: t.space[5],
      },
      "6": {
        margin: t.space[6],
      },
      "8": {
        margin: t.space[8],
      },
      "10": {
        margin: t.space[10],
      },
      "16": {
        margin: t.space[16],
      },
      "20": {
        margin: t.space[20],
      },
      "auto": {
        margin: "auto",
      },
    },
    mx: {
      "0": {
        marginLeft: t.space[0],
        marginRight: t.space[0],
      },
      "0.5": {
        marginLeft: t.space[0.5],
        marginRight: t.space[0.5],
      },
      "1": {
        marginLeft: t.space[1],
        marginRight: t.space[1],
      },
      "2": {
        marginLeft: t.space[2],
        marginRight: t.space[2],
      },
      "3": {
        marginLeft: t.space[3],
        marginRight: t.space[3],
      },
      "4": {
        marginLeft: t.space[4],
        marginRight: t.space[4],
      },
      "5": {
        marginLeft: t.space[5],
        marginRight: t.space[5],
      },
      "6": {
        marginLeft: t.space[6],
        marginRight: t.space[6],
      },
      "8": {
        marginLeft: t.space[8],
        marginRight: t.space[8],
      },
      "10": {
        marginLeft: t.space[10],
        marginRight: t.space[10],
      },
      "16": {
        marginLeft: t.space[16],
        marginRight: t.space[16],
      },
      "20": {
        marginLeft: t.space[20],
        marginRight: t.space[20],
      },
      "auto": {
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    my: {
      "0": {
        marginTop: t.space[0],
        marginBottom: t.space[0],
      },
      "0.5": {
        marginTop: t.space[0.5],
        marginBottom: t.space[0.5],
      },
      "1": {
        marginTop: t.space[1],
        marginBottom: t.space[1],
      },
      "2": {
        marginTop: t.space[2],
        marginBottom: t.space[2],
      },
      "3": {
        marginTop: t.space[3],
        marginBottom: t.space[3],
      },
      "4": {
        marginTop: t.space[4],
        marginBottom: t.space[4],
      },
      "5": {
        marginTop: t.space[5],
        marginBottom: t.space[5],
      },
      "6": {
        marginTop: t.space[6],
        marginBottom: t.space[6],
      },
      "8": {
        marginTop: t.space[8],
        marginBottom: t.space[8],
      },
      "10": {
        marginTop: t.space[10],
        marginBottom: t.space[10],
      },
      "16": {
        marginTop: t.space[16],
        marginBottom: t.space[16],
      },
      "20": {
        marginTop: t.space[20],
        marginBottom: t.space[20],
      },
      "auto": {
        marginTop: "auto",
        marginBottom: "auto",
      },
    },
    mt: {
      "0": {
        marginTop: t.space[0],
      },
      "0.5": {
        marginTop: t.space[0.5],
      },
      "1": {
        marginTop: t.space[1],
      },
      "2": {
        marginTop: t.space[2],
      },
      "3": {
        marginTop: t.space[3],
      },
      "4": {
        marginTop: t.space[4],
      },
      "5": {
        marginTop: t.space[5],
      },
      "6": {
        marginTop: t.space[6],
      },
      "8": {
        marginTop: t.space[8],
      },
      "10": {
        marginTop: t.space[10],
      },
      "16": {
        marginTop: t.space[16],
      },
      "20": {
        marginTop: t.space[20],
      },
      "auto": {
        marginTop: "auto",
      },
    },
    mb: {
      "0": {
        marginBottom: t.space[0],
      },
      "0.5": {
        marginBottom: t.space[0.5],
      },
      "1": {
        marginBottom: t.space[1],
      },
      "2": {
        marginBottom: t.space[2],
      },
      "3": {
        marginBottom: t.space[3],
      },
      "4": {
        marginBottom: t.space[4],
      },
      "5": {
        marginBottom: t.space[5],
      },
      "6": {
        marginBottom: t.space[6],
      },
      "8": {
        marginBottom: t.space[8],
      },
      "10": {
        marginBottom: t.space[10],
      },
      "16": {
        marginBottom: t.space[16],
      },
      "20": {
        marginBottom: t.space[20],
      },
      "auto": {
        marginBottom: "auto",
      },
    },
    ml: {
      "0": {
        marginLeft: t.space[0],
      },
      "0.5": {
        marginLeft: t.space[0.5],
      },
      "1": {
        marginLeft: t.space[1],
      },
      "2": {
        marginLeft: t.space[2],
      },
      "3": {
        marginLeft: t.space[3],
      },
      "4": {
        marginLeft: t.space[4],
      },
      "5": {
        marginLeft: t.space[5],
      },
      "6": {
        marginLeft: t.space[6],
      },
      "8": {
        marginLeft: t.space[8],
      },
      "10": {
        marginLeft: t.space[10],
      },
      "16": {
        marginLeft: t.space[16],
      },
      "20": {
        marginLeft: t.space[20],
      },
      "auto": {
        marginLeft: "auto",
      },
    },
    mr: {
      "0": {
        marginRight: t.space[0],
      },
      "0.5": {
        marginRight: t.space[0.5],
      },
      "1": {
        marginRight: t.space[1],
      },
      "2": {
        marginRight: t.space[2],
      },
      "3": {
        marginRight: t.space[3],
      },
      "4": {
        marginRight: t.space[4],
      },
      "5": {
        marginRight: t.space[5],
      },
      "6": {
        marginRight: t.space[6],
      },
      "8": {
        marginRight: t.space[8],
      },
      "10": {
        marginRight: t.space[10],
      },
      "16": {
        marginRight: t.space[16],
      },
      "20": {
        marginRight: t.space[20],
      },
      "auto": {
        marginRight: "auto",
      },
    },
  },
});

const marginStorybookArgs = {
  m: {
    description: "Margin on all four sides. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "Space Variants",
      defaultValue: { summary: "0" },
    },
  },
  mx: {
    description: "Margin on the x-axis. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "Space Variants",
      defaultValue: { summary: "0" },
    },
  },
  my: {
    description: "Margin on the y-axis. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "Space Variants",
      defaultValue: { summary: "0" },
    },
  },
  mt: {
    description: "Margin on the top side. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "Space Variants",
      defaultValue: { summary: "0" },
    },
  },
  mb: {
    description: "Margin on the bottom side. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "Space Variants",
      defaultValue: { summary: "0" },
    },
  },
  ml: {
    description: "Margin on the left side. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "Space Variants",
      defaultValue: { summary: "0" },
    },
  },
  mr: {
    description: "Margin on the right side. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "Space Variants",
      defaultValue: { summary: "0" },
    },
  },
};

const paddingVariants = css({
  variants: {
    p: {
      "0": {
        padding: t.space[0],
      },
      "0.5": {
        padding: t.space[0.5],
      },
      "1": {
        padding: t.space[1],
      },
      "2": {
        padding: t.space[2],
      },
      "3": {
        padding: t.space[3],
      },
      "4": {
        padding: t.space[4],
      },
      "5": {
        padding: t.space[5],
      },
      "6": {
        padding: t.space[6],
      },
      "8": {
        padding: t.space[8],
      },
      "10": {
        padding: t.space[10],
      },
      "16": {
        padding: t.space[16],
      },
      "20": {
        padding: t.space[20],
      },
    },
    px: {
      "0": {
        paddingLeft: t.space[0],
        paddingRight: t.space[0],
      },
      "0.5": {
        paddingLeft: t.space[0.5],
        paddingRight: t.space[0.5],
      },
      "1": {
        paddingLeft: t.space[1],
        paddingRight: t.space[1],
      },
      "2": {
        paddingLeft: t.space[2],
        paddingRight: t.space[2],
      },
      "3": {
        paddingLeft: t.space[3],
        paddingRight: t.space[3],
      },
      "4": {
        paddingLeft: t.space[4],
        paddingRight: t.space[4],
      },
      "5": {
        paddingLeft: t.space[5],
        paddingRight: t.space[5],
      },
      "6": {
        paddingLeft: t.space[6],
        paddingRight: t.space[6],
      },
      "8": {
        paddingLeft: t.space[8],
        paddingRight: t.space[8],
      },
      "10": {
        paddingLeft: t.space[10],
        paddingRight: t.space[10],
      },
      "16": {
        paddingLeft: t.space[16],
        paddingRight: t.space[16],
      },
      "20": {
        paddingLeft: t.space[20],
        paddingRight: t.space[20],
      },
    },
    py: {
      "0": {
        paddingTop: t.space[0],
        paddingBottom: t.space[0],
      },
      "0.5": {
        paddingTop: t.space[0.5],
        paddingBottom: t.space[0.5],
      },
      "1": {
        paddingTop: t.space[1],
        paddingBottom: t.space[1],
      },
      "2": {
        paddingTop: t.space[2],
        paddingBottom: t.space[2],
      },
      "3": {
        paddingTop: t.space[3],
        paddingBottom: t.space[3],
      },
      "4": {
        paddingTop: t.space[4],
        paddingBottom: t.space[4],
      },
      "5": {
        paddingTop: t.space[5],
        paddingBottom: t.space[5],
      },
      "6": {
        paddingTop: t.space[6],
        paddingBottom: t.space[6],
      },
      "8": {
        paddingTop: t.space[8],
        paddingBottom: t.space[8],
      },
      "10": {
        paddingTop: t.space[10],
        paddingBottom: t.space[10],
      },
      "16": {
        paddingTop: t.space[16],
        paddingBottom: t.space[16],
      },
      "20": {
        paddingTop: t.space[20],
        paddingBottom: t.space[20],
      },
    },
    pt: {
      "0": {
        paddingTop: t.space[0],
      },
      "0.5": {
        paddingTop: t.space[0.5],
      },
      "1": {
        paddingTop: t.space[1],
      },
      "2": {
        paddingTop: t.space[2],
      },
      "3": {
        paddingTop: t.space[3],
      },
      "4": {
        paddingTop: t.space[4],
      },
      "5": {
        paddingTop: t.space[5],
      },
      "6": {
        paddingTop: t.space[6],
      },
      "8": {
        paddingTop: t.space[8],
      },
      "10": {
        paddingTop: t.space[10],
      },
      "16": {
        paddingTop: t.space[16],
      },
      "20": {
        paddingTop: t.space[20],
      },
    },
    pb: {
      "0": {
        paddingBottom: t.space[0],
      },
      "0.5": {
        paddingBottom: t.space[0.5],
      },
      "1": {
        paddingBottom: t.space[1],
      },
      "2": {
        paddingBottom: t.space[2],
      },
      "3": {
        paddingBottom: t.space[3],
      },
      "4": {
        paddingBottom: t.space[4],
      },
      "5": {
        paddingBottom: t.space[5],
      },
      "6": {
        paddingBottom: t.space[6],
      },
      "8": {
        paddingBottom: t.space[8],
      },
      "10": {
        paddingBottom: t.space[10],
      },
      "16": {
        paddingBottom: t.space[16],
      },
      "20": {
        paddingBottom: t.space[20],
      },
    },
    pl: {
      "0": {
        paddingLeft: t.space[0],
      },
      "0.5": {
        paddingLeft: t.space[0.5],
      },
      "1": {
        paddingLeft: t.space[1],
      },
      "2": {
        paddingLeft: t.space[2],
      },
      "3": {
        paddingLeft: t.space[3],
      },
      "4": {
        paddingLeft: t.space[4],
      },
      "5": {
        paddingLeft: t.space[5],
      },
      "6": {
        paddingLeft: t.space[6],
      },
      "8": {
        paddingLeft: t.space[8],
      },
      "10": {
        paddingLeft: t.space[10],
      },
      "16": {
        paddingLeft: t.space[16],
      },
      "20": {
        paddingLeft: t.space[20],
      },
    },
    pr: {
      "0": {
        paddingRight: t.space[0],
      },
      "0.5": {
        paddingRight: t.space[0.5],
      },
      "1": {
        paddingRight: t.space[1],
      },
      "2": {
        paddingRight: t.space[2],
      },
      "3": {
        paddingRight: t.space[3],
      },
      "4": {
        paddingRight: t.space[4],
      },
      "5": {
        paddingRight: t.space[5],
      },
      "6": {
        paddingRight: t.space[6],
      },
      "8": {
        paddingRight: t.space[8],
      },
      "10": {
        paddingRight: t.space[10],
      },
      "16": {
        paddingRight: t.space[16],
      },
      "20": {
        paddingRight: t.space[20],
      },
    },
  },
});

const paddingStorybookArgs = {
  p: {
    description: "Padding on all four sides. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "Space Variants",
      defaultValue: { summary: "0" },
    },
  },
  px: {
    description: "Padding on the x-axis. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "Space Variants",
      defaultValue: { summary: "0" },
    },
  },
  py: {
    description: "Padding on the y-axis. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "Space Variants",
      defaultValue: { summary: "0" },
    },
  },
  pt: {
    description: "Padding on the top side. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "Space Variants",
      defaultValue: { summary: "0" },
    },
  },
  pb: {
    description: "Padding on the bottom side. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "Space Variants",
      defaultValue: { summary: "0" },
    },
  },
  pl: {
    description: "Padding on the left side. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "Space Variants",
      defaultValue: { summary: "0" },
    },
  },
  pr: {
    description: "Padding on the right side. The options are the space tokens.",
    control: { type: "select" },
    table: {
      category: "Space Variants",
      defaultValue: { summary: "0" },
    },
  },
};

const borderRadiusVariants = css({
  variants: {
    borderRadius: {
      "0": {
        borderRadius: t.radii[0],
      },
      "2": {
        borderRadius: t.radii[2],
      },
      "4": {
        borderRadius: t.radii[4],
      },
      "8": {
        borderRadius: t.radii[8],
      },
      "16": {
        borderRadius: t.radii[16],
      },
      "32": {
        borderRadius: t.radii[32],
      },
      "circle": {
        borderRadius: t.radii.circle,
      },
    },
  },
});

const borderRadiusStorybookArgs = {
  borderRadius: {
    description:
      "The border radius. If you want to specify different radii for each corner, drop into the `css` prop.",
    control: { type: "select" },
    table: {
      category: "Border Radius Variants",
      defaultValue: { summary: "0" },
    },
  },
};

const backgroundColorVariants = css({
  variants: {
    bgColor: {
      surfaceBackground: {
        backgroundColor: t.colors.surfaceBackground,
      },
      surfaceDefault: {
        backgroundColor: t.colors.surfaceDefault,
      },
      surfaceElevate: {
        backgroundColor: t.colors.surfaceElevate,
      },
      surfaceRaise: {
        backgroundColor: t.colors.surfaceRaise,
      },
      surfaceInverted: {
        backgroundColor: t.colors.surfaceInverted,
      },
      surfaceScrim: {
        backgroundColor: t.colors.surfaceScrim,
      },
      surfaceTransparent: {
        backgroundColor: t.colors.surfaceTransparent,
      },
      statusInformativeSurface: {
        backgroundColor: t.colors.statusInformativeSurface,
      },
      statusPositiveSurface: {
        backgroundColor: t.colors.statusPositiveSurface,
      },
      statusWarningSurface: {
        backgroundColor: t.colors.statusWarningSurface,
      },
      statusNegativeSurface: {
        backgroundColor: t.colors.statusNegativeSurface,
      },
      statusNeutralSurface: {
        backgroundColor: t.colors.statusNeutralSurface,
      },
      accentSurfaceElevate: {
        backgroundColor: t.colors.accentSurfaceElevate,
      },
      accentSurfaceRaise: {
        backgroundColor: t.colors.accentSurfaceRaise,
      },
      interactivePrimaryDefault: {
        backgroundColor: t.colors.interactivePrimaryDefault,
      },
      interactivePrimaryHover: {
        backgroundColor: t.colors.interactivePrimaryHover,
      },
      interactivePrimaryPressed: {
        backgroundColor: t.colors.interactivePrimaryPressed,
      },
      interactiveAccentDefault: {
        backgroundColor: t.colors.interactiveAccentDefault,
      },
      interactiveAccentHover: {
        backgroundColor: t.colors.interactiveAccentHover,
      },
      interactiveAccentPressed: {
        backgroundColor: t.colors.interactiveAccentPressed,
      },
      interactiveDefaultDefault: {
        backgroundColor: t.colors.interactiveDefaultDefault,
      },
      interactiveDefaultHover: {
        backgroundColor: t.colors.interactiveDefaultHover,
      },
      interactiveDefaultPressed: {
        backgroundColor: t.colors.interactiveDefaultPressed,
      },
      interactiveNegativeDefault: {
        backgroundColor: t.colors.interactiveNegativeDefault,
      },
      interactiveNegativeHover: {
        backgroundColor: t.colors.interactiveNegativeHover,
      },
      interactiveNegativePressed: {
        backgroundColor: t.colors.interactiveNegativePressed,
      },
      interactiveStatesActive: {
        backgroundColor: t.colors.interactiveStatesActive,
      },
      interactiveStatesSelected: {
        backgroundColor: t.colors.interactiveStatesSelected,
      },
      interactiveStatesDisabled: {
        backgroundColor: t.colors.interactiveStatesDisabled,
      },
      interactiveOverlayDefault: {
        backgroundColor: t.colors.interactiveOverlayDefault,
      },
      interactiveOverlayHover: {
        backgroundColor: t.colors.interactiveOverlayHover,
      },
      interactiveOverlayPressed: {
        backgroundColor: t.colors.interactiveOverlayPressed,
      },
      contentDefault: {
        backgroundColor: t.colors.contentDefault,
      },
      contentEmphasis: {
        backgroundColor: t.colors.contentEmphasis,
      },
      contentSecondary: {
        backgroundColor: t.colors.contentSecondary,
      },
      contentTertiary: {
        backgroundColor: t.colors.contentTertiary,
      },
      contentDisabled: {
        backgroundColor: t.colors.contentDisabled,
      },
      contentPlaceholder: {
        backgroundColor: t.colors.contentPlaceholder,
      },
      contentInverted: {
        backgroundColor: t.colors.contentInverted,
      },
      contentGood: {
        backgroundColor: t.colors.contentGood,
      },
      contentBad: {
        backgroundColor: t.colors.contentBad,
      },
      statusInformativeContent: {
        backgroundColor: t.colors.statusInformativeContent,
      },
      statusPositiveContent: {
        backgroundColor: t.colors.statusPositiveContent,
      },
      statusWarningContent: {
        backgroundColor: t.colors.statusWarningContent,
      },
      statusNegativeContent: {
        backgroundColor: t.colors.statusNegativeContent,
      },
      statusNeutralContent: {
        backgroundColor: t.colors.statusNeutralContent,
      },
      accentContentDefault: {
        backgroundColor: t.colors.accentContentDefault,
      },
      accentContentSecondary: {
        backgroundColor: t.colors.accentContentSecondary,
      },
      accentContentTertiary: {
        backgroundColor: t.colors.accentContentTertiary,
      },
      interactivePrimaryContent: {
        backgroundColor: t.colors.interactivePrimaryContent,
      },
      interactiveAccentContent: {
        backgroundColor: t.colors.interactiveAccentContent,
      },
      interactiveDefaultContent: {
        backgroundColor: t.colors.interactiveDefaultContent,
      },
      interactiveNegativeContent: {
        backgroundColor: t.colors.interactiveNegativeContent,
      },
    },
  },
});

const backgroundColorStorybookArgs = {
  bgColor: {
    description: "The background color. The options are the background color tokens.",
    control: { type: "select" },
    table: {
      category: "Color Variants",
    },
  },
};

const contentColorVariants = css({
  variants: {
    color: {
      contentDefault: {
        color: t.colors.contentDefault,
      },
      contentEmphasis: {
        color: t.colors.contentEmphasis,
      },
      contentSecondary: {
        color: t.colors.contentSecondary,
      },
      contentTertiary: {
        color: t.colors.contentTertiary,
      },
      contentDisabled: {
        color: t.colors.contentDisabled,
      },
      contentPlaceholder: {
        color: t.colors.contentPlaceholder,
      },
      contentInverted: {
        color: t.colors.contentInverted,
      },
      contentGood: {
        color: t.colors.contentGood,
      },
      contentBad: {
        color: t.colors.contentBad,
      },
      statusInformativeContent: {
        color: t.colors.statusInformativeContent,
      },
      statusPositiveContent: {
        color: t.colors.statusPositiveContent,
      },
      statusWarningContent: {
        color: t.colors.statusWarningContent,
      },
      statusNegativeContent: {
        color: t.colors.statusNegativeContent,
      },
      statusNeutralContent: {
        color: t.colors.statusNeutralContent,
      },
      accentContentDefault: {
        color: t.colors.accentContentDefault,
      },
      accentContentSecondary: {
        color: t.colors.accentContentSecondary,
      },
      accentContentTertiary: {
        color: t.colors.accentContentTertiary,
      },
      interactivePrimaryContent: {
        color: t.colors.interactivePrimaryContent,
      },
      interactiveAccentContent: {
        color: t.colors.interactiveAccentContent,
      },
      interactiveDefaultContent: {
        color: t.colors.interactiveDefaultContent,
      },
      interactiveNegativeContent: {
        color: t.colors.interactiveNegativeContent,
      },
    },
  },
});

const contentColorStorybookArgs = {
  color: {
    description: "The content color. The options are the content colors tokens.",
    control: { type: "select" },
    table: {
      category: "Color Variants",
    },
  },
};

const strokeColorVariants = css({
  variants: {
    color: {
      strokeDefault: {
        color: t.colors.strokeDefault,
      },
      strokeRaise: {
        color: t.colors.strokeRaise,
      },
      strokeStrong: {
        color: t.colors.strokeStrong,
      },
      strokeEmphasis: {
        color: t.colors.strokeEmphasis,
      },
      strokeElevate: {
        color: t.colors.strokeElevate,
      },
    },
  },
});

const strokeColorStorybookArgs = {
  color: {
    description: "The stroke color. The options are the stroke colors tokens.",
    control: { type: "select" },
    table: {
      category: "Color Variants",
    },
  },
};

const shadowVariants = css({
  variants: {
    boxShadow: {
      outerFocus: {
        boxShadow: t.shadows.outerFocus,
      },
      innerFocus: {
        boxShadow: t.shadows.innerFocus,
      },
      macroDown: {
        boxShadow: t.shadows.macroDown,
      },
      macroElevate: {
        boxShadow: t.shadows.macroElevate,
      },
      macroRaise: {
        boxShadow: t.shadows.macroRaise,
      },
      macroFloat: {
        boxShadow: t.shadows.macroFloat,
      },
      microDown: {
        boxShadow: t.shadows.microDown,
      },
      microElevate: {
        boxShadow: t.shadows.microElevate,
      },
      microRaise: {
        boxShadow: t.shadows.microRaise,
      },
      microFloat: {
        boxShadow: t.shadows.microFloat,
      },
    },
  },
});

const shadowStorybookArgs = {
  boxShadow: {
    description: "The box shadow. Uses the shadow tokens.",
    control: { type: "select" },
    table: {
      category: "Shadow Variants",
    },
  },
};

const typographyVariants = css({
  variants: {
    text: {
      titleXL: {
        fontFamily: t.fonts.titleXL,
        fontSize: t.fontSizes.titleXL,
        fontWeight: t.fontWeights.titleXL,
        lineHeight: t.lineHeights.titleXL,
        letterSpacing: t.letterSpacings.titleXL,
        textCase: t.textCases.titleXL,
        textDecoration: t.textDecorations.titleXL,
      },
      titleLG: {
        fontFamily: t.fonts.titleLG,
        fontSize: t.fontSizes.titleLG,
        fontWeight: t.fontWeights.titleLG,
        lineHeight: t.lineHeights.titleLG,
        letterSpacing: t.letterSpacings.titleLG,
        textCase: t.textCases.titleLG,
        textDecoration: t.textDecorations.titleLG,
      },
      titleDefault: {
        fontFamily: t.fonts.titleDefault,
        fontSize: t.fontSizes.titleDefault,
        fontWeight: t.fontWeights.titleDefault,
        lineHeight: t.lineHeights.titleDefault,
        letterSpacing: t.letterSpacings.titleDefault,
        textCase: t.textCases.titleDefault,
        textDecoration: t.textDecorations.titleDefault,
      },
      titleMD: {
        fontFamily: t.fonts.titleMD,
        fontSize: t.fontSizes.titleMD,
        fontWeight: t.fontWeights.titleMD,
        lineHeight: t.lineHeights.titleMD,
        letterSpacing: t.letterSpacings.titleMD,
        textCase: t.textCases.titleMD,
        textDecoration: t.textDecorations.titleMD,
      },
      titleSM: {
        fontFamily: t.fonts.titleSM,
        fontSize: t.fontSizes.titleSM,
        fontWeight: t.fontWeights.titleSM,
        lineHeight: t.lineHeights.titleSM,
        letterSpacing: t.letterSpacings.titleSM,
        textCase: t.textCases.titleSM,
        textDecoration: t.textDecorations.titleSM,
      },
      titleXS: {
        fontFamily: t.fonts.titleXS,
        fontSize: t.fontSizes.titleXS,
        fontWeight: t.fontWeights.titleXS,
        lineHeight: t.lineHeights.titleXS,
        letterSpacing: t.letterSpacings.titleXS,
        textCase: t.textCases.titleXS,
        textDecoration: t.textDecorations.titleXS,
      },
      label: {
        fontFamily: t.fonts.label,
        fontSize: t.fontSizes.label,
        fontWeight: t.fontWeights.label,
        lineHeight: t.lineHeights.label,
        letterSpacing: t.letterSpacings.label,
        textCase: t.textCases.label,
        textDecoration: t.textDecorations.label,
      },
      labelStrong: {
        fontFamily: t.fonts.labelStrong,
        fontSize: t.fontSizes.labelStrong,
        fontWeight: t.fontWeights.labelStrong,
        lineHeight: t.lineHeights.labelStrong,
        letterSpacing: t.letterSpacings.labelStrong,
        textCase: t.textCases.labelStrong,
        textDecoration: t.textDecorations.labelStrong,
      },
      paragraph: {
        fontFamily: t.fonts.paragraph,
        fontSize: t.fontSizes.paragraph,
        fontWeight: t.fontWeights.paragraph,
        lineHeight: t.lineHeights.paragraph,
        letterSpacing: t.letterSpacings.paragraph,
        textCase: t.textCases.paragraph,
        textDecoration: t.textDecorations.paragraph,
      },
      paragraphStrong: {
        fontFamily: t.fonts.paragraphStrong,
        fontSize: t.fontSizes.paragraphStrong,
        fontWeight: t.fontWeights.paragraphStrong,
        lineHeight: t.lineHeights.paragraphStrong,
        letterSpacing: t.letterSpacings.paragraphStrong,
        textCase: t.textCases.paragraphStrong,
        textDecoration: t.textDecorations.paragraphStrong,
      },
      paragraphLink: {
        fontFamily: t.fonts.paragraphLink,
        fontSize: t.fontSizes.paragraphLink,
        fontWeight: t.fontWeights.paragraphLink,
        lineHeight: t.lineHeights.paragraphLink,
        letterSpacing: t.letterSpacings.paragraphLink,
        textCase: t.textCases.paragraphLink,
        textDecoration: t.textDecorations.paragraphLink,
      },
      paragraphMono: {
        fontFamily: t.fonts.paragraphMono,
        fontSize: t.fontSizes.paragraphMono,
        fontWeight: t.fontWeights.paragraphMono,
        lineHeight: t.lineHeights.paragraphMono,
        letterSpacing: t.letterSpacings.paragraphMono,
        textCase: t.textCases.paragraphMono,
        textDecoration: t.textDecorations.paragraphMono,
      },
      body: {
        fontFamily: t.fonts.body,
        fontSize: t.fontSizes.body,
        fontWeight: t.fontWeights.body,
        lineHeight: t.lineHeights.body,
        letterSpacing: t.letterSpacings.body,
        textCase: t.textCases.body,
        textDecoration: t.textDecorations.body,
      },
      bodyStrong: {
        fontFamily: t.fonts.bodyStrong,
        fontSize: t.fontSizes.bodyStrong,
        fontWeight: t.fontWeights.bodyStrong,
        lineHeight: t.lineHeights.bodyStrong,
        letterSpacing: t.letterSpacings.bodyStrong,
        textCase: t.textCases.bodyStrong,
        textDecoration: t.textDecorations.bodyStrong,
      },
      bodyLink: {
        fontFamily: t.fonts.bodyLink,
        fontSize: t.fontSizes.bodyLink,
        fontWeight: t.fontWeights.bodyLink,
        lineHeight: t.lineHeights.bodyLink,
        letterSpacing: t.letterSpacings.bodyLink,
        textCase: t.textCases.bodyLink,
        textDecoration: t.textDecorations.bodyLink,
      },
      bodyMono: {
        fontFamily: t.fonts.bodyMono,
        fontSize: t.fontSizes.bodyMono,
        fontWeight: t.fontWeights.bodyMono,
        lineHeight: t.lineHeights.bodyMono,
        letterSpacing: t.letterSpacings.bodyMono,
        textCase: t.textCases.bodyMono,
        textDecoration: t.textDecorations.bodyMono,
      },
      caption: {
        fontFamily: t.fonts.caption,
        fontSize: t.fontSizes.caption,
        fontWeight: t.fontWeights.caption,
        lineHeight: t.lineHeights.caption,
        letterSpacing: t.letterSpacings.caption,
        textCase: t.textCases.caption,
        textDecoration: t.textDecorations.caption,
      },
      captionStrong: {
        fontFamily: t.fonts.captionStrong,
        fontSize: t.fontSizes.captionStrong,
        fontWeight: t.fontWeights.captionStrong,
        lineHeight: t.lineHeights.captionStrong,
        letterSpacing: t.letterSpacings.captionStrong,
        textCase: t.textCases.captionStrong,
        textDecoration: t.textDecorations.captionStrong,
      },
      captionLink: {
        fontFamily: t.fonts.captionLink,
        fontSize: t.fontSizes.captionLink,
        fontWeight: t.fontWeights.captionLink,
        lineHeight: t.lineHeights.captionLink,
        letterSpacing: t.letterSpacings.captionLink,
        textCase: t.textCases.captionLink,
        textDecoration: t.textDecorations.captionLink,
      },
      captionMono: {
        fontFamily: t.fonts.captionMono,
        fontSize: t.fontSizes.captionMono,
        fontWeight: t.fontWeights.captionMono,
        lineHeight: t.lineHeights.captionMono,
        letterSpacing: t.letterSpacings.captionMono,
        textCase: t.textCases.captionMono,
        textDecoration: t.textDecorations.captionMono,
      },
      hint: {
        fontFamily: t.fonts.hint,
        fontSize: t.fontSizes.hint,
        fontWeight: t.fontWeights.hint,
        lineHeight: t.lineHeights.hint,
        letterSpacing: t.letterSpacings.hint,
        textCase: t.textCases.hint,
        textDecoration: t.textDecorations.hint,
      },
      hintStrong: {
        fontFamily: t.fonts.hintStrong,
        fontSize: t.fontSizes.hintStrong,
        fontWeight: t.fontWeights.hintStrong,
        lineHeight: t.lineHeights.hintStrong,
        letterSpacing: t.letterSpacings.hintStrong,
        textCase: t.textCases.hintStrong,
        textDecoration: t.textDecorations.hintStrong,
      },
      hintLink: {
        fontFamily: t.fonts.hintLink,
        fontSize: t.fontSizes.hintLink,
        fontWeight: t.fontWeights.hintLink,
        lineHeight: t.lineHeights.hintLink,
        letterSpacing: t.letterSpacings.hintLink,
        textCase: t.textCases.hintLink,
        textDecoration: t.textDecorations.hintLink,
      },
      hintMono: {
        fontFamily: t.fonts.hintMono,
        fontSize: t.fontSizes.hintMono,
        fontWeight: t.fontWeights.hintMono,
        lineHeight: t.lineHeights.hintMono,
        letterSpacing: t.letterSpacings.hintMono,
        textCase: t.textCases.hintMono,
        textDecoration: t.textDecorations.hintMono,
      },
      eyebrow: {
        fontFamily: t.fonts.eyebrow,
        fontSize: t.fontSizes.eyebrow,
        fontWeight: t.fontWeights.eyebrow,
        lineHeight: t.lineHeights.eyebrow,
        letterSpacing: t.letterSpacings.eyebrow,
        textCase: t.textCases.eyebrow,
        textDecoration: t.textDecorations.eyebrow,
      },
      eyebrowStrong: {
        fontFamily: t.fonts.eyebrowStrong,
        fontSize: t.fontSizes.eyebrowStrong,
        fontWeight: t.fontWeights.eyebrowStrong,
        lineHeight: t.lineHeights.eyebrowStrong,
        letterSpacing: t.letterSpacings.eyebrowStrong,
        textCase: t.textCases.eyebrowStrong,
        textDecoration: t.textDecorations.eyebrowStrong,
      },
    },
  },
});

const typographyStorybookArgs = {
  text: {
    description: "The typography style for the text. Follows the semantic text tokens.",
    control: { type: "select" },
    table: {
      category: "Text Variants",
    },
  },
};
export {
  backgroundColorStorybookArgs,
  backgroundColorVariants,
  borderRadiusStorybookArgs,
  borderRadiusVariants,
  contentColorStorybookArgs,
  contentColorVariants,
  gapStorybookArgs,
  gapVariants,
  marginStorybookArgs,
  marginVariants,
  paddingStorybookArgs,
  paddingVariants,
  shadowStorybookArgs,
  shadowVariants,
  sizeStorybookArgs,
  sizeVariants,
  strokeColorStorybookArgs,
  strokeColorVariants,
  typographyStorybookArgs,
  typographyVariants,
};
