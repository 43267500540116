import styled, { CSSObject } from "@emotion/styled";
import { motion, MotionProps } from "framer-motion";

import {
  IBorderStyles,
  IBoxStyles,
  IFlexStyles,
  IGridStyles,
  withBorderStyles,
  withBoxStyles,
  withFlexStyles,
  withGridStyles,
} from "~src/shared/helpers/sharedComponentProps";

import { containerVariants, IContainerVariants } from "./variants";

export type ILegacyBoxProps = {
  textAlign?: CSSObject["textAlign"];
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  fullWidth?: boolean;

  height?: string;
  minHeight?: string;
  maxHeight?: string;
  fullHeight?: boolean;
  type?: IContainerVariants;
  solidBg?: boolean;
  borderRadius?: string;

  overflow?: CSSObject["overflow"];

  onClick?: React.MouseEventHandler<HTMLDivElement>;
  ref?: React.LegacyRef<HTMLDivElement>;
} & IGridStyles &
  IFlexStyles &
  IBoxStyles &
  IBorderStyles &
  MotionProps &
  Pick<React.ComponentProps<typeof motion.div>, "className" | "ref">;

/**
 * Atomic box element.
 */
export const OldBoxDontUse: React.FC<ILegacyBoxProps> = styled(motion.div)<ILegacyBoxProps>`
  box-sizing: border-box;

  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
  max-width: ${(props) => props.maxWidth};
  width: ${(props) => props.fullWidth !== undefined && "100%"};

  height: ${(props) => props.height};
  min-height: ${(props) => props.minHeight};
  max-height: ${(props) => props.maxHeight};
  height: ${(props) => props.fullHeight !== undefined && "100%"};
  border-radius: ${(props) => props.borderRadius};

  text-align: ${(props) => props.textAlign};

  :hover {
    cursor: ${(props) => props.onClick && "pointer"};
  }

  overflow: ${(props) => props.overflow};

  ${(props) => props.solidBg !== undefined && containerVariants.base(props.theme)}
  ${(props) => props.type && containerVariants[props.type](props.theme)}

  ${(props) => withGridStyles(props)}
  ${(props) => withFlexStyles(props)}
  ${(props) => withBoxStyles(props)}
  /* TODO: We need to figure out our border props better. */
  ${(props) => withBorderStyles(props, props.theme.old.navBorder)}
`;
