// eslint-disable-next-line no-restricted-imports
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import clsx from "clsx";
import React, { useCallback, useMemo } from "react";
import { createBreakpoint } from "react-use";

import { lightTheme, mobileTheme } from "~src/designSystem/theme";

import { themeDark } from "../theme/darkVariant";
import { themeLight } from "../theme/lightVariant";
import { useDarkMode } from "./useDarkMode";

type IProps = {
  force?: "dark" | "light";
  children: React.ReactNode;
};

// This is the sm breakpoint as defined in Stitches.
const useBreakpoint = createBreakpoint({ mobile: 0, desktop: 672 });

export const ThemeProvider: React.FC<IProps> = ({ force, children }) => {
  const darkMode = useDarkMode(useCallback((s) => s.darkMode, []));
  const breakpoint = useBreakpoint();

  const emotionTheme = useMemo(() => {
    if (force === "dark") {
      return themeDark;
    }
    if (force === "light") {
      return themeLight;
    }
    if (darkMode) {
      return themeDark;
    }
    return themeLight;
  }, [darkMode, force]);

  const stitchesTheme = useMemo(() => {
    // The default is dark mode, so return nothing if we're dark mode.
    const colorTheme = ((): string => {
      if (force === "dark") {
        return "";
      }
      if (force === "light") {
        return lightTheme.toString();
      }
      if (darkMode) {
        return "";
      }
      return lightTheme.toString();
    })();

    const screenTheme = breakpoint === "mobile" ? mobileTheme.toString() : "";

    return clsx(colorTheme, screenTheme);
  }, [darkMode, force, breakpoint]);

  return (
    <EmotionThemeProvider theme={emotionTheme}>
      <div className={stitchesTheme}>{children}</div>
    </EmotionThemeProvider>
  );
};
