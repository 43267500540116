import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useMemo } from "react";
import { ToastBar as RHToastBar, Toaster as RHToaster } from "react-hot-toast";
import {
  Renderable as IRenderable,
  ToastType as IToastType,
} from "react-hot-toast/dist/core/types";

import { BlueprintIcon } from "~src/designSystem/deprecated/BlueprintIcon";
import { t } from "~src/designSystem/theme";

/**
 * A custom Toaster.
 *
 * We currently don't support custom icons since we don't have a need for them.
 *
 * See https://react-hot-toast.com/docs/version-2#custom-renderer-api.
 */
export const Toaster: React.FC = () => (
  <RHToaster>
    {(toast) => (
      <RHToastBar style={{ padding: 0, backgroundColor: "transparent" }} toast={toast}>
        {/* This could also take an icon prop, but I'm lazy. */}
        {({ message }) => <Toast type={toast.type} message={message} toastID={toast.id} />}
      </RHToastBar>
    )}
  </RHToaster>
);

export type IToastProps = {
  type: IToastType;
  message: IRenderable;
  toastID: string;
};

export const Toast: React.FC<IToastProps> = (props) => {
  const theme = useTheme();

  const icon = useMemo(() => {
    if (props.type === "success") {
      return <BlueprintIcon icon="tick" size={16} color={theme.components.Toast.Icon.success} />;
    }
    if (props.type === "error") {
      return <BlueprintIcon icon="error" size={16} color={theme.components.Toast.Icon.error} />;
    }
    return <BlueprintIcon icon="info-sign" size={16} color={theme.components.Toast.Icon.info} />;
  }, [props.type, theme]);

  return (
    <ToastWrapper>
      <IconWrapper>{icon}</IconWrapper>
      <Message>{props.message}</Message>
    </ToastWrapper>
  );
};

const ToastWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  gap: ${t.space[2].toString()};

  width: fit-content;
  max-width: 300px;

  padding: ${t.c.spacing("2", "3")};
  border-radius: ${t.radii[4].toString()};

  background-color: ${(props) => props.theme.components.Toast.Background};
`;

const IconWrapper = styled.div`
  flex-shrink: 0;
  line-height: 0;
`;

const Message = styled.div`
  flex-grow: 1;
  text-align: center;

  color: ${(props) => props.theme.components.Toast.Text};
`;
