import create from "zustand";
import { persist } from "zustand/middleware";

type IDarkModeStore = {
  darkMode: boolean;
  setDarkMode: (darkMode: boolean) => void;
};

export const useDarkMode = create<IDarkModeStore>(
  persist(
    (set) => ({
      darkMode: true,
      setDarkMode: (darkMode) => set({ darkMode }),
    }),
    { name: "zus-dark-mode" },
  ),
);
