import styled from "@emotion/styled";

import { ILegacyBoxProps, OldBoxDontUse } from "~src/designSystem/deprecated/Box";
import { IFlexStyles, withFlexStyles } from "~src/shared/helpers/sharedComponentProps";

export type IFlexboxProps = {
  row?: boolean;
  gap?: string;
} & ILegacyBoxProps &
  IFlexStyles;

/** Atomic flexbox element
 *
 * a flexible container for general use.
 */
export const PleaseGoUseFlexInstead = styled(OldBoxDontUse)<IFlexboxProps>`
  display: flex;
  flex-direction: ${(props) => (props.row === true ? "row" : "column")};
  gap: ${(props) => props.gap};
  ${(props) => withFlexStyles(props)}
`;
