/**
 * We define our Stitches configuration & theme in this file.
 */

import { createStitches, globalCss } from "@stitches/react";

import {
  typeDesktopSemantics,
  typeMobileSemantics,
  uiDark,
  uiLight,
  // eslint-disable-next-line import/no-restricted-paths
} from "./codegen";

// This theme defaults to DESKTOP DARK.
//
// We include 2 custom scales:
// - Text Case
// - Text Decoration
const { styled, css, theme, createTheme, getCssText } = createStitches({
  theme: {
    colors: uiDark.colors,
    shadows: uiDark.shadows,
    space: {
      0: "0px",
      0.5: "2px",
      1: "4px",
      2: "8px",
      3: "12px",
      4: "16px",
      5: "20px",
      6: "24px",
      8: "32px",
      10: "40px",
      16: "64px",
      20: "80px",
    },
    // These are not a part of the Copper design system, but we provide them
    // so Engineering has width tokens that snap to the 8pt grid. This maps fairly
    // closely to Tailwind's size scale. Given that they have seen success and we don't
    // know how we should be restricting width at the time of writing, this is a fairly
    // safe bet IMO.
    //
    // Bless Vim's <C-r>= built in calculator!
    sizes: {
      0: "0px",
      0.5: "2px",
      1: "4px",
      2: "8px",
      3: "12px",
      4: "16px",
      5: "20px",
      6: "24px",
      7: "28px",
      8: "32px",
      9: "36px",
      10: "40px",
      12: "48px",
      14: "56px",
      16: "64px",
      18: "72px",
      20: "80px",
      24: "96px",
      28: "112px",
      32: "128px",
      36: "144px",
      40: "160px",
      44: "176px",
      48: "192px",
      52: "208px",
      56: "224px",
      60: "240px",
      64: "256px",
      72: "288px",
      80: "320px",
      96: "384px",
      104: "416px",
      112: "448px",
      120: "480px",
      136: "544px",
      152: "608px",
    },
    borderWidths: {
      0: "0px",
      1: "1px",
      2: "2px",
      4: "4px",
    },
    borderStyles: {
      solid: "solid",
      dashed: "dashed",
      dotted: "dotted",
      double: "double",
      hidden: "hidden",
      none: "none",
    },
    radii: {
      0: "0px",
      2: "2px",
      4: "4px",
      8: "8px",
      16: "16px",
      32: "32px",
      circle: "9999px",
    },
    fonts: typeDesktopSemantics.fontFamily,
    fontSizes: typeDesktopSemantics.fontSize,
    fontWeights: typeDesktopSemantics.fontWeight,
    lineHeights: typeDesktopSemantics.lineHeight,
    letterSpacings: typeDesktopSemantics.letterSpacing,
    textCases: typeDesktopSemantics.textCase,
    textDecorations: typeDesktopSemantics.textDecoration,
    transitions: {
      ease: "all .2s ease",
    },
    zIndices: {
      10: "10",
      20: "20",
      30: "30",
      40: "40",
      50: "50",
    },
  },
  media: {
    // The screen sizes are:
    //   xs   = 440px
    //   sm   = 576px
    //   md   = 768px
    //   lg   = 992px
    //   xl   = 1200px
    //   xxl  = 1440px
    //   xxxl = 1920px.
    //
    // We are desktop first; each breakpoint sits between the screen sizes.
    xxl: "(max-width: 1680px)",
    xl: "(max-width: 1320px)",
    lg: "(max-width: 1092px)",
    md: "(max-width: 880px)",
    sm: "(max-width: 672px)",
    xs: "(max-width: 508px)",
  },

  utils: {},
  prefix: "copper",
});

const lightTheme = createTheme({
  colors: uiLight.colors,
  shadows: uiLight.shadows,
});

const mobileTheme = createTheme({
  fonts: typeMobileSemantics.fontFamily,
  fontSizes: typeMobileSemantics.fontSize,
  fontWeights: typeMobileSemantics.fontWeight,
  lineHeights: typeMobileSemantics.lineHeight,
  letterSpacings: typeMobileSemantics.letterSpacing,
  textCases: typeMobileSemantics.textCase,
  textDecorations: typeMobileSemantics.textDecoration,
});

type IColor = keyof typeof theme.colors;
type IBorderWidth = keyof typeof theme.borderWidths;
type IBorderStyle = keyof typeof theme.borderStyles;
type ISpace = keyof typeof theme.space;
type IRadius = keyof typeof theme.radii;

const spaceComposition = (a: ISpace, b?: ISpace, c?: ISpace, d?: ISpace): string => {
  if (b && c && d) {
    return `${theme.space[a]} ${theme.space[b]} ${theme.space[c]} ${theme.space[d]}`;
  }
  if (b && c) {
    return `${theme.space[a]} ${theme.space[b]} ${theme.space[c]}`;
  }
  if (b) {
    return `${theme.space[a]} ${theme.space[b]}`;
  }
  return `${theme.space[a]}`;
};

const borderComposition = (width: IBorderWidth, style: IBorderStyle, color: IColor): string => {
  return `${theme.borderWidths[width]} ${theme.borderStyles[style]} ${theme.colors[color]}`;
};

const radiiComposition = (a: IRadius, b?: IRadius, c?: IRadius, d?: IRadius): string => {
  if (b && c && d) {
    return `${theme.radii[a]} ${theme.radii[b]} ${theme.radii[c]} ${theme.radii[d]}`;
  }
  if (b && c) {
    return `${theme.radii[a]} ${theme.radii[b]} ${theme.radii[c]}`;
  }
  if (b) {
    return `${theme.radii[a]} ${theme.radii[b]}`;
  }
  return `${theme.radii[a]}`;
};

// t is the Stitches theme with some of our own convenience
// functions attached.
const t = {
  // Copy over the tokens.
  colors: theme.colors,
  shadows: theme.shadows,
  space: theme.space,
  sizes: theme.sizes,
  borderWidths: theme.borderWidths,
  borderStyles: theme.borderStyles,
  radii: theme.radii,
  fonts: theme.fonts,
  fontSizes: theme.fontSizes,
  fontWeights: theme.fontWeights,
  lineHeights: theme.lineHeights,
  letterSpacings: theme.letterSpacings,
  textCases: theme.textCases,
  textDecorations: theme.textDecorations,
  transitions: theme.transitions,
  zIndices: theme.zIndices,

  // Add some functions for easier composition of tokens.
  c: {
    spacing: spaceComposition,
    border: borderComposition,
    borderRadius: radiiComposition,
  },
} as const;

const globalStyles = globalCss({
  body: {
    // Defaults for our text.
    "color": t.colors.contentDefault,
    "fontFamily": `${t.fonts.body}, sans-serif`,
    "fontWeight": t.fontWeights.body,
    "fontSize": t.fontSizes.body,
    "lineHeight": t.lineHeights.body,
    "letterSpacing": t.letterSpacings.body,
    /* various font improvements https://wp-mix.com/font-smoothing-chrome-firefox/ */
    "-webkit-font-smoothing": "antialiased",
    "-moz-osx-font-smoothing": "antialiased",
    "fontVariant": "tabular-nums",
    "fontFeatureSettings": '"tnum", "tnum"',
    // Minimum width for responsiveness.
    "minWidth": "360px",
  },
});

const partial = <Props,>(
  Component: React.FC<Props>,
  partialProps: Partial<Props>,
): React.FC<Props> => {
  const PartialComponent = (props: Props) => <Component {...partialProps} {...props} />;
  return PartialComponent;
};

// darkTheme, lightTheme, and mobileTheme should not be imported for day-to-day use.
export {
  css,
  theme as darkTheme,
  getCssText,
  globalStyles,
  lightTheme,
  mobileTheme,
  partial,
  styled,
  t,
};
