import { styled, t } from "~src/designSystem/theme";
import { castToStitchesStoryComponent } from "~src/storybook/utils";

export const Spacer = styled("div", {
  // Locally scoped tokens for CSS override spacing.
  $$x: "0",
  $$y: "0",

  paddingLeft: "$$x",
  paddingBottom: "$$y",

  variants: {
    x: {
      "0": {
        paddingLeft: t.space[0],
      },
      "0.5": {
        paddingLeft: t.space[0.5],
      },
      "1": {
        paddingLeft: t.space[1],
      },
      "2": {
        paddingLeft: t.space[2],
      },
      "3": {
        paddingLeft: t.space[3],
      },
      "4": {
        paddingLeft: t.space[4],
      },
      "5": {
        paddingLeft: t.space[5],
      },
      "6": {
        paddingLeft: t.space[6],
      },
      "8": {
        paddingLeft: t.space[8],
      },
      "10": {
        paddingLeft: t.space[10],
      },
      "16": {
        paddingLeft: t.space[16],
      },
      "20": {
        paddingLeft: t.space[20],
      },
    },
    y: {
      "0": {
        paddingBottom: t.space[0],
      },
      "0.5": {
        paddingBottom: t.space[0.5],
      },
      "1": {
        paddingBottom: t.space[1],
      },
      "2": {
        paddingBottom: t.space[2],
      },
      "3": {
        paddingBottom: t.space[3],
      },
      "4": {
        paddingBottom: t.space[4],
      },
      "5": {
        paddingBottom: t.space[5],
      },
      "6": {
        paddingBottom: t.space[6],
      },
      "8": {
        paddingBottom: t.space[8],
      },
      "10": {
        paddingBottom: t.space[10],
      },
      "16": {
        paddingBottom: t.space[16],
      },
      "20": {
        paddingBottom: t.space[20],
      },
    },
  },
});

export const StorybookSpacer = castToStitchesStoryComponent(Spacer);

export const StorybookSpacerArgs = {
  x: {
    description: "Horizontal space. Implemented via padding-left. These are the spacing tokens.",
    control: { type: "select" },
    table: {
      category: "Spacer Variants",
    },
  },
  y: {
    description: "Vertical space. Implemented via padding-bottom. These are the spacing tokens.",
    control: { type: "select" },
    table: {
      category: "Spacer Variants",
    },
  },
};
